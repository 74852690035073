export default function integer() {
  return {
    validate(input) {
      if (input.value === "") {
        return { valid: true };
      }
      const opts = Object.assign(
        {},
        {
          decimalSeparator: ".",
          thousandsSeparator: "",
        },
        input.options
      );
      const decimalSeparator =
        opts.decimalSeparator === "." ? "\\." : opts.decimalSeparator;
      const thousandsSeparator =
        opts.thousandsSeparator === "." ? "\\." : opts.thousandsSeparator;
      const testRegexp = new RegExp(
        `^-?[0-9]{1,3}(${thousandsSeparator}[0-9]{3})*(${decimalSeparator}[0-9]+)?$`
      );
      const thousandsReplacer = new RegExp(thousandsSeparator, "g");
      let v = `${input.value}`;
      if (!testRegexp.test(v)) {
        return { valid: false };
      }
      if (thousandsSeparator) {
        v = v.replace(thousandsReplacer, "");
      }
      if (decimalSeparator) {
        v = v.replace(decimalSeparator, ".");
      }
      const n = parseFloat(v);
      return { valid: !isNaN(n) && isFinite(n) && Math.floor(n) === n };
    },
  };
}
