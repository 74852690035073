import format from "../utils/format";
export default function between() {
  const formatValue = (value) => {
    return parseFloat(`${value}`.replace(",", "."));
  };
  return {
    validate(input) {
      const value = input.value;
      if (value === "") {
        return { valid: true };
      }
      const opts = Object.assign(
        {},
        { inclusive: true, message: "" },
        input.options
      );
      const minValue = formatValue(opts.min);
      const maxValue = formatValue(opts.max);
      return opts.inclusive
        ? {
            message: format(
              input.l10n
                ? opts.message || input.l10n.between.default
                : opts.message,
              [`${minValue}`, `${maxValue}`]
            ),
            valid:
              parseFloat(value) >= minValue && parseFloat(value) <= maxValue,
          }
        : {
            message: format(
              input.l10n
                ? opts.message || input.l10n.between.notInclusive
                : opts.message,
              [`${minValue}`, `${maxValue}`]
            ),
            valid: parseFloat(value) > minValue && parseFloat(value) < maxValue,
          };
    },
  };
}
