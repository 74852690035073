import Vue from "vue";
// mutation types
export const LOAD_TEAMS = "loadTeams";
export const SET_TEAMS = "setTeams";
export const SET_DEFAULT_TEAM = "setDefaultTeam";
import JwtService from "@/core/services/jwt.service";

export default {
  state: {
    myTeams: [],
    defaultTeam: {},
  },
  getters: {
    /**
     * Get list of teams
     * @param state
     * @returns {*}
     */
    myTeams(state) {
      return state.myTeams;
    },
    defaultTeam(state) {
      return state.defaultTeam;
    },
  },
  actions: {
    [LOAD_TEAMS](state) {
      let token = JwtService.getToken();
      if (!token || token == "undefined") return Promise.resolve();

      if (state.getters.myTeams && state.getters.myTeams.length > 0)
        return Promise.resolve();

      return Vue.axios
        .get("/api/profile/teams?enrich=true")
        .then((response) => {
          state.commit(SET_TEAMS, response.data);

          for (let i = 0; i < response.data.length; i++) {
            let t = response.data[i];
            if (t.defaultTeam) {
              state.commit(SET_DEFAULT_TEAM, t);
              break;
            }
          }
        })
        .catch(() => {});
    },
  },
  mutations: {
    [SET_TEAMS](state, payload) {
      state.myTeams = payload;
    },

    [SET_DEFAULT_TEAM](state, payload) {
      state.defaultTeam = payload;
    },
  },
};
