import ApiService from "@/core/services/api.service";

// action types
export const API_REQUEST = "request";

// todo: I couldn't understand, why is it need this method ??!!!?
const actions = {
  [API_REQUEST](context, params) {
    return new Promise(function (resolve, reject) {
      if (params.requestType.trim().toUpperCase() === "POST") {
        ApiService.post(params.resource, params.dto)
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response);
          });
      } else {
        ApiService.query(params.resource, { params: params.requestParams })
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response);
          });
      }
    });
  },
};

export default {
  actions,
};
