import { uuid4 } from "@/common/uuid";

//
export function createWidget(type, params) {
  return {
    x: 0,
    y: 0,
    w: 4,
    h: 2,
    i: uuid4(),

    type,

    measureParams: {
      ...params,
    },
  };
}

export function createExistingWidget(payload) {
  return {
    x: payload.x,
    y: payload.y,
    w: payload.w,
    h: payload.h,
    i: payload.i,

    type: payload.type,

    measureParams: {
      ...payload.params,
    },
  };
}

export function copyWidget(params) {
  return {
    ...params,
    //
    x: 0,
    y: 0,
    w: 4,
    h: 2,
    i: uuid4(),
  };
}

export const ChartType = {
  Undefined: "",
  Line: "ChartTypeLine",
  Scatter: "ChartTypeScatter",
  Bubble: "ChartTypeBubble",

  // Circle Charts
  Pie: "ChartTypePie",
  Radar: "ChartTypeRadar",
  Table: "ChartTypeTable",
  Map: "ChartTypeMap",

  // Grid
  Grid: "ChartTypeGrid",
};

export function getTypeName(key) {
  return String(key).replace("ChartType", "");
}

export class GridSettings {
  constructor(config) {
    return {
      colNum: 12,
      margin: [15, 15],

      isDraggable: true,
      isResizable: true,
      preventCollision: false,
      isMirrored: false,
      verticalCompact: true,
      useCssTransforms: true,

      widgets: [],

      ...(config || {}),
    };
  }
}
