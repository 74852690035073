// Spain
export const locale = {
  TRANSLATOR: {
    SELECT: "Elige lengua",
    LANGUAGE: "Language",
  },
  COMMON: {
    "YES": "Sí",
    "NO": "No",
    HELP: "Help",
    GO: "Vamos",
    AUTHOR: "Autor",
    REPLACE: "Reemplazar",
    BROWSE_FILE: "Buscar Archivo",
    DOCUMENT_UPLOADED: "Documento cargado con éxito",
    DOWNLOAD: "Descargar",
    ACTIONS: "Acciones",
    ACTIVE: "Activo",
    ADD: "Añadir",
    ALL_DEVICES: "Todos los dispositivos",
    ALTITUDE: "Altura",
    APPLY: "Aceptar",
    ARE_YOU_SURE: "¿Estas seguro?",
    ARE_YOU_SURE_WANT_TO_CANCEL:
      "¿Estás seguro de que deseas cancelar tu suscripción %{name}?",
    ARE_YOU_SURE_YOU_WANT_TO_CANCEL:
      "¿Estas seguro que quieres cancelar %{name}?",
    ARE_YOU_SURE_YOU_WANT_TO_CHANGE:
      "¿Estás seguro de que quieres cambiar la tarifa %{name}?",
    ARE_YOU_SURE_YOU_WANT_TO_DELETE:
      "¿Estas seguro que quieres borrarlo %{name}?",
    ARE_YOU_SURE_YOU_WANT_TO_RESTORE:
      "¿Estás seguro de que quieres restaurar %{name}?",
    BACK: "Regresar",
    BEGIN_DATE: "Fecha de inicio",
    CANCEL: "Cancelar",
    CANCELED_SUCCESSFULLY: "Cancelado con éxito",
    CATALOGUE: "Grupo de dispositivos",
    CHANGE: "Editar",
    CHANGED: "Modificado",
    CHOOSE_FILE_OR_DROP:
      "Seleccione un archivo o arrástrelo y suéltelo aquí ...",
    CLEAR: "Limpiar",
    CLOSE: "Cerca",
    CODE: "Сódigo",
    COMMAND: "Mando",
    CONFIRMATION: "La confirmación",
    CREATE: "Crear",
    CREATED: "Creado por",
    DELETED_SUCCESSFULLY: "Eliminado con éxito",
    DESCRIPTION: "Descripción",
    DEVICE: "Dispositivo",
    DROP_FILE_HERE: "Arrastra el archivo aquí ...",
    EMPTY_DATA: "No hay datos que coincidan con las condiciones especificadas",
    END_DATE: "Fecha final",
    ERROR: "Error",
    EXAMPLE: "Ejemplo",
    FILE: "Expediente",
    HOURS: "Reloj",
    INFO: "Información",
    LATITUDE: "Latitud",
    LONGITUDE: "Longitud",
    MAIN: "El principal",
    MAX_VALUE: "Max. valor",
    MIN_VALUE: "Min. valor",
    MINUTES: "Minutos",
    NAME: "Nombre",
    NEXT: "Siguiendo",
    NEXT_STEP: "Siguiente",
    NO_DATE: "Fecha no seleccionada",
    NO_TIME: "Hora no seleccionada",
    PARAMETERS: "Parámetros",
    PRICE: "Precio",
    SAVE: "Guardar",
    SAVED_SUCCESSFULLY: "Guardado exitosamente",
    SEARCH: "Buscar",
    SELECTED: "Seleccionado",
    SELECTED_DEVICES: "Dispositivos seleccionados",
    SHARE: "Compartir este",
    SHOW_BY: "Mostrar",
    START_DATE: "Fecha de inicio",
    SUCCESS: "Éxito",
    TAB_MAIN: "Lo esencial",
    TEAM: "Mando",
    THIS_FIELD_IS_REQUIRED: "Este campo es requerido",
    TITLE: "Título",
    TRIAL: "Trial hasta",
    TYPE: "Un tipo",
    TYPE_CATALOGUE: "Tipo de catalogo",
    TYPE_DEVICE: "Tipo de dispositivo",
    UNKNOWN_ERROR: "Error desconocido",
    USER: "Usuario",
    WARNING: "Advertencia",
    PLEASE_WAIT: "Espere por favor...",
  },
  COUNTRIES: {
    COUNTRY: "País",
    COUNTRIES: "País",
  },
  ACTIONS: {
    CREATE_NEW: "Crear un",
    EDIT: "Editar",
    DELETE: "Eliminar",
    RELOAD: "Reiniciar",
    IMPORT_DATA: "Importación de datos",
    IMPORT: "Importar",
    ADD: "Añadir",
    VIEW: "Ver",
    EXECUTE: "Ejecutar",
    SHOW: "Mostrar",
  },
  MAP: {
    SELECT_COORDS_AND_RADIUS: "Seleccionar coordenadas y radio",
    CHANGE: "Editar",
    CHANGE_POINT_AND_RADIUS: "Seleccionar punto y radio",
    RESET: "Reiniciar",
  },
  MONITORING: {
    // todo translate
    "TYPE": "Tipo de dispositivo",
    "TEAM": "Equipo",
    "IMEI": "IMEI",
    "NO_DATA": "Sin datos",
    "ACTIVE": "Activo",
    "LAST_DATA_RECEIVED": "Últimos datos recibidos",
    "PARAMETERS": "Parámetros",
    ORDER: "Orden",
    ADD_NEW_DEVICE: "Agrega un nuevo dispositivo",
    REALLY_BUY: "¿De verdad quieres comprar %{name}?",
    SUCCESS_CREATED_ORDER:
      "El pedido se ha creado correctamente. Vaya a Equipo-> Pedidos para pagar.",
    NO_CATALOGUE_SELECTED: "Grupo de dispositivos no seleccionado",
    MODAL: {
      APPLY: "Aplicar",
      SELECT:
        "Seleccione su tarifa para abrir el acceso a la funcionalidad completa de la plataforma y comenzar a resolver sus tareas profesionales",
      INPUT: "Si tiene un código de promoción, ingréselo aquí",
      INPUT_PLACEHOLDER: "Código promocional",
    },
  },
  FEEDBACK: {
    SEND_FEEDBACK: "Enviar comentarios",
    DESCRIPTION_PROBLEM: "Describe tu problema o comentario aquí",
    SCREENSHOT: "Captura de pantalla",
    MAKE_SCREENSHOT: "Haga clic aquí para tomar una captura de pantalla",
    SUBMIT: "Enviar mensaje",
    SELECT_RECTANGLE:
      "Dibuja un rectángulo para recortar la captura de pantalla",
    FEEDBACK_SENT: "¡Revisión enviada!",
  },
  DEVICE: {
    COMMANDS_NOT_FOUND: "No se encontraron comandos para este dispositivo",
    IMEI: "IMEI",
    EXECUTE_DEVICE_COMMAND: "Ejecutar comando de dispositivo",
    SELECT_COMMAND: "-- Selecciona un equipo --",
    OTHER_TEAM_PUBLIC_DEVICE:
      "Este es un dispositivo compartido de un equipo diferente; puede usarlo en modo de solo lectura. Puedes crear tú mismo un dispositivo con acceso completo.",
    TYPE_DEVICE_TYPE_NAME: "Ingrese el nombre del tipo de dispositivo",
    PHONE_NUMBER: "Número de teléfono",
    CHOOSE_COORDINATES: "Seleccionar coordenadas",
    PUBLIC: "Público",
    PARAMETER_MAPPING: "Relación de parámetros",
    ADDITIONAL_DEVICES: "Otros dispositivos",
    PARAMETER_ADD_INFO:
      "Puede agregar un parámetro para su dispositivo. No es necesario especificar el siguiente conjunto de parámetros manualmente, ya que se configuró automáticamente:",
    ADD_PARAMETER_MAPPING: "Agregar parámetro",
    EDIT_PARAMETER_MAPPING: "Editar parámetro",
    TRANSLATE_EXPRESSION: "Expresión",
    DEVICE_TYPE: "Tipo de dispositivo",
    IMPORT_DEVICE_DATA: "Importando datos del dispositivo",
    LAST_DATA_RECEIVED: "Última fecha de intercambio",
    CREATE_NEW_DEVICE: "Creando un nuevo dispositivo",
    PARAMETER: "Parámetro",
    EDIT_DEVICE: "Dispositivo de edición",
    /*Device types*/
    LOCATION_MANUAL: "Ubicación manual",
    INTEGRATION: "Integración",
    TRUST_LEVEL: "Nivel de confianza",
    AMEND_TIMEZONE: "Editar la zona horaria",
    COMMANDS: "Comandos de control",
    ADD_COMMAND: "Agregar comando de control",
    EDIT_COMMAND: "Editar comando de control",
    VALUE: "Valor",
    ADD_PROPERTY: "Agregar propiedad",
    CODE: "Сódigo",
    CREATE_NEW_DEVICE_TYPE: "Crear un nuevo tipo de dispositivo",
    EDIT_DEVICE_TYPE: "Editar el tipo de dispositivo",
    SELECT_DEVICE_TYPE: "-- Seleccione el tipo de dispositivo --",
    DEFAULT_VALUE: "Valor por defecto",
    DEVICE: "Dispositivo",
    DEVICES: "Dispositivos",
    IMPORT_COMPLETED: "Importación completada",
    IMPORT_FAILED: "Importación fallida",
    IMPORT_FAILED: "Exportación fallida",
    DEVICE_FIELDS: "Additional options",
    FIELD_ERROR: "Incorrect value of an additional field",
    REMOVE_COMPOUND: "Remove parameters",
    ADD_COMPOUND: "Add parameters",
    COMPOUND_ENTRY: "Span parameters",
    CHOOSE_COMPOUND: "Select a number",
    COMPOUND_PROMPT: "Select a number from the list",
    COMPOUND_GROUP_PROMPT: "Select a group from the list",
    SPAN: "Span",
    SPAN1: "Test", // TODO: Это тестовая строка
    GRIDNUM: "Net",
    SENSORNUM: "Sensor",
  },
  CATALOGUE: {
    NAME: "Nombre del directorio",
    DESCRIPTION: "Descripción del catálogo",
    DEVICES: "Dispositivos",
    /*CHOOSE_DEVICE: "Choose device",
    SELECTED_DEVICES: "Selected devices",*/
    CREATE_NEW_CATALOGUE: "Crear un nuevo grupo de dispositivos",
    EDIT_CATALOGUE: "Edición del grupo de dispositivos",
  },
  MESSAGES: {
    MEASURED: "Medido",
    DEVICE: "Dispositivo",
    MESSAGE_TYPE: "Tipo de mensaje",
    DATA: "Datos",
    SELECT_MESSAGE_TYPE: "-- Seleccionar tipo de mensaje --",
    UNIQUE_ID: "Identificación única",
  },
  PARAMETERS: {
    TYPE_TEAM_NAME: "Ingrese el nombre del equipo",
    SELECT_UNIT: "-- Seleccione una unidad --",
    NAME: "Nombre",
    DESCRIPTION: "Descripción",
    CODE: "Сódigo",
    UNIT: "Unidad de medida",
    GLOBAL: "Global",
    CIRCLE: "Circular",
    CONCENTRATION: "Concentración",
    CALCULATED: "Calculado",
    CALCULATE_EXPRESSION: "Fórmula",
    ATTACHMENT: "El adjunto",
    CREATE_NEW_PARAMETER: "Crear un nuevo parámetro",
    EDIT_PARAMETER: "Editar un parámetro",
    ALERT:
      "Edición de parámetros Este es un parámetro global; solo puede usarlo en modo lectura.\n" +
      "                Puede crear su propio parámetro de acceso completo.",
    PARAMETER: "Parámetro",
    PARAMETERS: "Parámetros",
  },
  DATA_PACKAGES: {
    RADIUS: "Radio",
    PARAMETERS: "Parámetros",
    CREATE_NEW_DATA_PACKAGE: "Crear un nuevo paquete de datos",
    EDIT_DATA_PACKAGE: "Editar un paquete de datos",
    NO_DEVICE_FOUND: "No se encontraron dispositivos en el área seleccionada",
    PREVIEW_IMAGE: "Imagen de vista previa para la tienda de datos",
  },
  ACCOUNTS: {
    SELECT_ACCOUNT_TYPE: "-- Seleccione su tipo de cuenta --",
    SELECT_CURRENCY: "-- Elegir Moneda --",
    CURRENCY: "Moneda",
    BALANCE: "Equilibrar",
    EXTERNAL_ID: "ID externo",
    TEAM_NAME: "Nombre del equipo",
  },
  TRANSACTIONS: {
    SELECT_TRANSACTION_TYPE: "-- Seleccione el tipo de transacción --",
    TYPE_USER_NAME: "Ingrese su nombre de usuario",
    AMOUNT: "Número",
    BALANCE_BEFORE: "Equilibrio hasta",
    BALANCE_AFTER: "Equilibrio después",
    TRANSACTION_TYPE: "Tipo de transacción",
  },
  PAYMENTS: {
    SELECT_PAYMENT_TYPE: "-- Seleccione un método de pago --",
    SELECT_PAYMENT_SYSTEM: "-- Elija un sistema de pago --",
    SELECT_PAYMENT_STATE: "-- Seleccionar estado de pago --",
    PAYMENT_SYSTEM: "Sistema de pago",
    PAYMENT_STATE: "Estado de pago",
    FEE: "Comisión",
    REGISTERED_AMOUNT: "Monto registrado",
    TOTAL_AMOUNT: "Cantidad total",
    CANCEL_PAYMENT: "Cancelar el pago",
    RESTORE_PAYMENT: "Recuperar pago",
    ACCOUNT: "Cuenta",
    TYPE_ACCOUNT: "ТTipo de cuenta",
    EXTERNAL_IDENTIFIER: "ID externo",
    PAYMENT_UID: "UID de pago",
    CREATE_NEW_PAYMENT: "Crear nuevo pago",
    PAYMENT_SUCCESSFUL: "¡El pago fue exitoso!",
    PAYMENT_ERROR: "Pago rechazado",
    PAYMENT_THANK_YOU:
      "Gracias por tu pago. Un recibo de pago automático será enviado a su correo electrónico.",
    PAYMENT_ERROR_MESSAGE:
      "Hubo un problema al procesar su pago. Inténtalo de nuevo.",
    PAYMENT_GO_TO_MY_TEAM: "A la página del equipo",
  },
  PRODUCTS: {
    SELECT_PRODUCT_TYPE: "-- Seleccione el tipo de producto --",
    PRODUCT_TYPE_NAME: "Tipo de artículo",
    PRICE: "Precio",
    REFERENCE: "Referencia de objeto",
    PRIMARY: "Principal",
    OWNERSHIP: "Tipo de Propiedad",
    LIFETIME: "Esperanza de vida (msec)",
    CHOOSE_PRODUCT: "Elige un producto",
    CREATE_NEW_PRODUCT: "Creando un nuevo producto",
    EDIT_PRODUCT: "Edición de productos",
    DEFERMENT: "Aplazamiento (msec)",
  },
  DISCOUNTS: {
    DISCOUNT_TYPE_NAME: "Nombre del tipo de descuento",
    REUSABLE: "Reutilizable",
    SELECT_DISCOUNT_TYPE: "-- Seleccionar tipo de descuento --",
    SECRET_CODE: "Código promocional",
    ONCE_FOR_USER: "Una vez para el usuario",
    CREATE_NEW_DISCOUNT: "Creando un nuevo descuento",
    EDIT_DISCOUNT: "Edición de descuentos",
  },
  ORDERS: {
    SELECT_ORDER_TYPE: "-- Seleccionar tipo de orden --",
    USER_NAME: "Nombre de usuario",
    DISCOUNT_NAME: "Nombre de descuento",
    TARGET_CURRENCY_NAME: "Moneda",
    PAYMENT_SYSTEM_NAME: "Sistema de pago",
    ORDER_STATE_NAME: "Estado de la orden",
    DELETE_ORDER: "Eliminar orden",
    CANCEL_ORDER: "Cancelar el pedido",
    ORDER_STATE: "Estado de la orden",
    DISCOUNT: "Un descuento",
    PAYMENT_SYSTEM: "Sistema de pago",
    DISCOUNT_AMOUNT: "Importe de descuento",
    PAID: "Pagado",
    VIEW_ORDER: "Ver pedido",
  },
  PAYMENT_INFO: {
    ABOUT_PAYMENT_RULES: "Rules of payment",
    PAYMENT_RULES_HEADER:
      "Rules of payment and the security of online payments, confidentiality of the information",
    PAYMENT_RULES_CONTENT_1:
      "Online payments via bank cards is carried out through JSC “Alpha-Bank” (Public Joint Stock Company).",
    // eslint-disable-next-line max-len
    PAYMENT_RULES_CONTENT_2:
      "The payment service via the Internet is carried out in accordance with the Rules of the international payment systems Visa, MasterCard on the principles of confidentiality and security of the payment, using the most modern methods of verification, encryption and data transmission over closed communication channels. The bank card data is entered on the secure payment page of Alpha-Bank JSC.",
    // eslint-disable-next-line max-len
    PAYMENT_RULES_CONTENT_3:
      "On the bank card data entry page, you will need to enter the bank card data: card number, cardholder name, expiry date, three-digit security code (CVV2 for VISA, CVC2 for MasterCard). All the necessary data are printed on the card itself. The three-digit security code is the three digits on the back of the card.",
    PAYMENT_RULES_CONTENT_4:
      "Next, you will be redirected to your Bank’s page to enter the security code (3D-Secure code), that will be sent to you via SMS. If you do not receive the security code, you should contact the Bank that issued your card.",
    CASES_TO_REFUSAL: "Cases when your Bank can decline the payment:",
    REFUSAL_CASE_1:
      "your bank card is not designed for making payments via the Internet, this you can find out by contacting your Bank;",
    REFUSAL_CASE_2:
      "due to insufficient funds on the bank card. You can contact the bank that issued the card and find out more information about the availability of funds on your bank card;",
    REFUSAL_CASE_3: "bank card details entered incorrectly;",
    REFUSAL_CASE_4:
      "your bank card has expired. The validity period of the bank card is usually indicated on the front side of the card (the month and year until which the card is valid). The information about the validity period of the card You can find out by contacting the Bank that issued the card.",
    FOR_PAYMENT_QUESTIONS:
      "For bank card payments and other issues related to the website, You can contact the following phone number: %{phone}.",
    INFO_IS_CONFIDENTIAL:
      "The personal information provided by You (name, address, phone number, e-mail, bank card number) is confidential and not subject to disclosure. Your credit card data is transmitted only in encrypted form and is not stored on our Web server.",
    RETURN_POLICY: "Return policy",
    CASH_REFUNDS_NOT_ALLOWED:
      "Cash refunds are not allowed for card payments. The refund procedure is governed by the rules of international payment systems.",
    REFUND_REGULATED_BY:
      "The procedure for returning goods is regulated by Article 26.1 of the Federal Law “On Consumer Rights Protection”.",
    REFUND_RULE_1:
      "The consumer has the right to refuse the product at any time prior to its transfer, and after the transfer - within seven days;",
    REFUND_RULE_2:
      "The return of proper quality products is possible if its commercial appearance, consumer properties are preserved, as well as a document confirming the fact and conditions of purchase of the specified product;",
    REFUND_RULE_3:
      "The consumer does not have the right to refuse a product of proper quality that has  individually-specific properties, if the specified product can be used exclusively by the person purchasing it;",
    REFUND_RULE_4:
      "If the consumer refuses a product, the seller must return to him the sum of money paid by the consumer under the contract, with the exception of the seller’s expenses for the delivery of the returned  product from the consumer, no later than ten days from the date of the consumer's relevant request.",
    REFUND_1:
      "In order to return funds to the bank card, it is necessary to fill in the “Application for Return of Funds”, which the company sends upon request to the email address and send it along with attached a copy of Your passport to %{email}",
    REFUND_2:
      "The refund will be made to the bank card within 21 (twenty-one) weekdays from the day of receipt of the “Application for Return of Funds” by the Company.",
    REFUND_3:
      "In order to return funds for transactions made with errors, it is necessary to submit a written application and attach a copy of the passport and the cheques/receipts confirming the erroneous write-offs. This application should be sent to %{email}",
    REFUND_4:
      "The refund amount will be equal to the purchase amount. The period of consideration of the Application and the refund of funds shall begin from the moment the Company receives the Application and is calculated in weekdays excluding holidays/weekends.",
    CONTACT_INFO: "Contact details",
    ALLOWED_CARDS: "VISA, MasterCard are accepted for the payment.",
    COMPANY_NAME: "Company",
    INN: "Taxpayer Identification Number",
    OGRN: "Primary State Registration Number",
    KPP: "KPP",
    ADDRESS: "Address",
    PHONE: "Contact phone",
  },
  TARIFFS: {
    MAX_REQUEST_COUNT: "Max. número de solicitudes",
    MAX_USER_COUNT: "Max. número de usuarios",
    MAX_DEVICE_COUNT: "Max. número de dispositivos",
    MAX_API_REQUEST_COUNT: "Max. número de solicitudes de API",
    MAX_MANAGED_TEAM_COUNT: "Max. número de equipos controlados",
    COUNTRIES: "País",
    CREATE_NEW_TARIFF: "Creación de una nueva tarifa",
    EDIT_TARIFF: "Editar tarifa",
    ALLOW_LOGO_CUSTOMIZATION: "Se permiten cambios de logotipo",
    ALLOW_IP_FILTRATING: "Restricción de acceso IP permitida",
    ALLOW_REQUEST_LOGS: "Acceso a los registros de solicitudes",
  },
  CURRENCIES: {
    RUBLES: "Rublos rusos",
    USD: "Dólar estadounidense",
    EURO: "Euro",
  },
  TEAMS: {
    ADD_MAIL: "Presiona Enter para agregar",
    MAILING_LIST: "Lista de correo",
    SELECT_TEAM_TYPE: "-- Seleccionar tipo de equipo --",
    SELECT_COUNTRY: "-- Seleccione un país --",
    TYPE_COUNTRY_NAME: "Escriba el nombre del país",
    OWNER: "Propietario",
    TARIFF: "Tarífa",
    WELCOME_TO_NATLOGGER:
      "¡Bienvenido a la plataforma SaaS de Natlogger! Por favor complete la información sobre\n" +
      "              A su equipo a continuación. Puede crear varios equipos separados (empresas) con diferentes tarifas\n" +
      "              y cambiar entre ellos.",
    CHANGE_TARIFF: "Cambiar tarifa",
    CREATE_TEAM_IS_MANAGED:
      "Equipo administrado (pagado desde la cuenta del equipo seleccionado actualmente)",
    TAX_NUMBER: "Número de identificación fiscal",
    ADDRESS: "La dirección",
    PHONE: "Teléfono",
    EMAIL: "Correo electrónico",
    WEB: "Sitio web",
    MEMBER: "Partícipe",
    MEMBERS: "Participantes",
    NOTIFICATION_RULE: "Regla de notificación",
    NOTIFICATION_RULES: "Reglas de notificación",
    ORDERS: "Pedidos",
    COUNTRY: "Un país",
    EXISTED_USER: "Usuario",
    OR_ENTER_EMAIL:
      "O ingrese una dirección de correo electrónico si el usuario aún no está registrado",
    PERMISSION: "Permisos",
    SELECT_PERMISSION: "-- Seleccionar resolución --",
    PERMISSION_NOT_SET: "Permiso no establecido",
    USER_NOT_SET: "Usuario no configurado",
    NOTIFICATION_RULE_TYPE: "Tipo de regla de notificación",
    SELECT_NOTIFICATION_RULE_TYPE:
      "-- Seleccione el tipo de regla de notificación --",
    SELECT_TARIFF: "-- Elige una tarifa --",
    OWNERSHIP_TYPE: "Tipo de Propiedad",
    TOTAL: "Total",
    STATE: "Condición",
    SUBSCRIPTION_CANCELLED: "Suscripción cancelada",
    CREATE_NEW_TEAM: "Creando un nuevo equipo",
    EDIT_TEAM: "Editar un equipo",
    PLEASE_SET_COUNTRY: "Por favor indique su pais",
    PLEASE_SELECT_TEAM_TYPE: "Seleccione un tipo de equipo",
    INVITATION_SENT_SUCCESSFULLY: "Invitación enviada con éxito",
    CANCEL_SUBSCRIPTION: "Darse de baja",
    SUBSCRIPTION_CANCELLED_SUCCESSFULLY: "Suscripción cancelada con éxito",
    ORDER_DELETED_SUCCESSFULLY: "Pedido eliminado correctamente",
    PLEASE_SET_COUNTRY_BEFORE: "Configure su país y guarde el comando",
    TARIFF_CHANGED_PLEASE:
      "Se ha cambiado la tarifa. Pague en la sección 'Pedidos'.",
    LOGO: {
      EDIT: "Cambiar logo",
      UNDO: "Cancelar cambios",
      DELETE: "Quitar logo",
    },
    DEF_EXP: "aplazamiento hasta",
    ALLOWED_ADDRESSES:
      "Lista de direcciones IP permitidas (en una nueva línea)",
    TEAM_FIELDS_NOTE:
      "Por favor, rellene y guarde estos campos sólo una vez. A partir de ahí, siempre serán sacados automáticamente a las facturas y actas. Lo necesitamos mucho, para el control de divisas y de impuestos de la Federación de Rusia.",
    COMPANY_NAME: "Nombre de la empresa",
    DIRECTOR_NAME: "Nombre e iniciales del director",
    KPP: "KPP",
    OGRN: "OGRN",
    RS: "Checking account",
    BANK_NAME: "Bank",
    CORRESPONDENT_ACCOUNT: "Correspondent account",
    BIK: "Bank SWIFT code",
    PLEASE_PROVIDE_CORRECT_DATA:
      "Complete los campos obligatorios antes de guardar el equipo",
    VIEW_ORDER_DOCS: "Generar documentos de pedido",
    UPLOAD_ORDER_DOCS: "Cargar documentos firmados",
    PROFORMA_INVOICE: "Proforma invoice",
    ACCEPTANCE_ACT: "Acceptance act",
    INVOICE: "Invoice",
    PLEASE_SELECT_CURRENCY: "Por favor seleccione moneda",
    SELECT_CURRENCY: "Seleccione moneda",
    BACK_TO_DOCUMENT_SELECTION: "Volver a la selección de documentos",
    ARE_YOU_SURE_YOU_WANT_TO_DELETE_ORDER:
      "¿Está seguro de que desea eliminar el pedido?",
    ARE_YOU_SURE_YOU_WANT_TO_CANCEL_SUB:
      "¿Estás seguro de que deseas cancelar la suscripción?",
    SELECT_TEAM_COUNTRY_FIRST: "Ingrese primero su país en la sección General.",
  },
  USERS: {
    PATRONYMIC: "Segundo nombre",
    AUTHORIZATION_SCHEDULE: "Calendario de autorización",
    ACTIVE_UNTIL: "Válido hasta",
    APPROVED: "Aprobado",
    BLOCKED: "Bloqueado",
    PASSWORD: "Contraseña",
    EMPTY_MEANS_NO_CHANGES: "Vacío significa que no hay cambios",
    CONFIRM_PASSWORD: "Confirma la contraseña",
    IDENTIFIERS: "Identificadores",
    CODE: "Сódigo",
    VERIFIED: "Verificado",
    CREATE_NEW_USER: "Creando una nueva cuenta",
    EDIT_USER: "Edición de usuario",
    PASSWORD_MISMATCH: "Las contraseñas no coinciden",
    USER_SUCCESSFULLY_REGISTERED: "Usuario registrado exitosamente",
    IDENTIFIER_VALUE_IS_NOT_SET: "Valor de ID no establecido",
    IDENTIFIER_TYPE_IS_NOT_SET: "Tipo de ID no establecido",
  },
  ROLES: {
    GRANTS: "Derechos",
    CREATE_NEW_ROLE: "Creando un nuevo rol",
    EDIT_ROLE: "Edición de roles",
  },
  TOKENS: {
    TYPE_IDENTIFIER_VALUE: "Ingrese el valor de ID",
    IDENTIFIER: "Identificador",
    LAST_REQUEST: "Última petición",
    CREATE_NEW_TOKEN: "Creando un token nuevo",
    EDIT_TOKEN: "Edición de tokens",
    VALUE: "Valor",
  },
  PROPERTIES: {
    VISIBLE_FOR_ADMIN: "Al parecer para el administrador",
    CREATE_NEW_PROPERTY: "Creando una nueva propiedad",
    EDIT_PROPERTY: "Editar propiedades",
  },
  ML_MODELS: {
    CREATE_NEW_MODEL: "Crear nuevo modelo",
    EDIT_MODEL: "Editar modelo",
  },
  TEMPLATES: {
    SELECT_TEMPLATE_TYPE: "-- Elija un tipo de plantilla --",
    LOCALE: "Idioma",
    BODY: "Cuerpo",
    CREATE_NEW_TEMPLATE: "Crear una nueva plantilla",
    EDIT_TEMPLATE: "Editar una plantilla",
  },
  NOTIFICATIONS: {
    TITLE: "Título",
    SOURCE: "Una fuente",
    DESTINATION: "Cita",
    NOTIFICATION_STATE_NAME: "Estado de notificación",
    SELECT_NOTIFICATION_TYPE: "-- Seleccionar tipo de notificación --",
  },
  PASSPHRASE_TYPES: {
    VALIDATION_REGEX: "Verificar regex",
    ENCRYPTION: "Cifrado",
    GENERATOR: "Generador",
    CREATE_NEW_PASSPHRASE_TYPE: "Crear un nuevo tipo de contraseña",
    EDIT_PASSPHRASE_TYPE: "Edición del tipo de contraseña",
  },
  TASKS: {
    SERVICE_NAME: "Nombre del Servicio",
    LAST_EXECUTION_DATE: "Fecha de la última ejecución",
    EXECUTE_LOG: "Ver registro",
    CRON_EXPRESSION: "Expresión de cron",
    CREATE_NEW_TASK: "Crea una nueva tarea",
    EDIT_TASK: "Editar tarea",
    TASK: "Tarea",
    STATE: "Condición",
    METHOD: "Método",
    TASK_EXECUTION_LOG: "Registro de ejecución de tareas",
  },
  FEEDBACKS: {
    QUESTION: "Pregunta",
    TEXT: "Texto",
    FEEDBACK_TYPE: "Tipo de Comentarios",
    VIEW_FEEDBACK: "Ver",
  },
  NEWS: {
    SORT_ORDER: "El orden de clasificación",
    ACTIVE_FROM: "Activo desde",
    ACTIVE_TO: "Activo hasta",
    NEWS: "Noticias",
    DATE: "Fecha",
    BLOG: "Blog",
    CREATE_NEW_BLOG: "Crear una nueva publicación de blog",
  },
  PERSONAL_NOTIFICATIONS: {
    IS_READ: "Leer",
  },
  REPORTS: {
    PART_TYPE: "Parte del informe", //todo
    SORT_ORDER: "El orden de clasificación",
    GENERATE_REPORT: "Generar informe",
    GROUP: "Grupo",
    VIEW: "Monitor",
    PLEASE_SPECIFY_PARAMETERS_FOR: "Especifique los parámetros para el {name}",
    REPORT: "Reporte",
    DATE_IS_NOT_SPECIFIED: "Fecha no especificada",
    THIS_A_GLOBAL_REPORT:
      "Este es un informe global; puede interactuar con él en modo de solo lectura. Puede crear su propio informe de acceso completo.",
    PARTS: "Partes del informe",
    REPORT_PART_TYPE: "Tipo de parte del informe",
    ANNOTATION: "Anotación",
    SELECT_GROUP_TYPE: "-- Seleccione el tipo de agrupación de datos --",
    VIEW_TYPE: "Tipo de visualización",
    SELECT_VIEW_TYPE: "-- Seleccionar tipo de pantalla --",
    CREATE_NEW_REPORT: "Crear nuevo informe",
    EDIT_REPORT: "Editar informe",
    GENERATE: "Generar",
    NEW_REPORT_PART: "Nueva parte del informe",
  },
  CHECKOUT: {
    PLEASE_ENTER_YOUR_EMAIL: "Please enter your email in Profile page",
    ORDER_HAS_INVALID_STATE: "El pedido tiene un estado no válido",
    CREATE_CHECKOUT: "Crear cálculo",
    DONE: "Hecho",
    INFO: "Información",
    DISCOUNT: "Un descuento",
    SUMMARY: "En total",
    SUCCESSFULLY_PURCHASED:
      "El pedido se ha pagado correctamente. Por favor espera...",
    CREATION_DATE: "Fecha de creación",
    OWNERSHIP_TYPE: "Tipo de Propiedad",
    PRODUCTS: "Productos",
    PRICE: "Precio",
    CURRENCY: "Moneda",
    PROMO_DESCRIPTION:
      "Si tiene un código de promoción, ingréselo en el campo a continuación. De lo contrario, deje el campo en blanco.",
    PROMOCODE: "Código promocional",
    TOTAL_AMOUNT: "Total",
    ALERT:
      "Si desea utilizar el período de prueba, posponga el pago hasta su finalización.",
  },
  QUICK_USER: {
    HI: "Hola",
    USER_PROFILE: "Perfil del usuario",
    SIGN_OUT: "Desconectarse",
    CURRENT_TEAM: "Equipo actual",
    CLICK_TO_SWITCH: "Haga clic para cambiar",
    ADD_TEAM: "Agregar cuenta",
  },
  FOOTER: {
    FEEDBACK: "Retroalimentación",
    PRICING: "Precios",
    BLOG: "Blog",
    CONTACT: "Contactos",
  },

  MENU: {
    ML_MODELS: "ML models",
    DASHBOARD: "Escritorio",
    MONITORING: "Monitoreo",
    // DATA_SHOP: "Tienda de datos",
    DEVICES: "Dispositivos",
    CATALOGUES: "Grupos de dispositivos",
    MESSAGES: "Publicaciones",
    PARAMETERS: "Parámetros",
    REPORTS: "Informes",
    MANAGEMENT: "Controlar",
    TEAMS: "Comandos",
    DEVICE_TYPES: "Tipos de dispositivos",
    DATA_PACKAGES: "Paquetes de datos",
    CONTENT: "Contenido",
    BLOGRU: "Blog | Ruso",
    BLOGEN: "Blog | Inglés",
    BLOGES: "Blog | Español",
    FINANCE: "Finanzas",
    ACCOUNTS: "Cuentas personales",
    TRANSACTIONS: "Actas",
    PAYMENTS: "Pagos",
    PRODUCTS: "Productos",
    DISCOUNTS: "Descuentos.",
    ORDERS: "Pedidos",
    TARIFFS: "Tarifas",
    ADMINISTRATION: "Administración",
    USERS: "Usuarios",
    ROLES: "Roles",
    TOKENS: "Tokens",
    PROPERTIES: "Propiedades",
    TEMPLATES: "Plantillas",
    NOTIFICATIONS: "Notificaciones",
    PASSPHRASE_TYPES: "Tipos de contraseñas",
    TASKS: "Tareas",
    FEEDBACKS: "Retroalimentación",
    DOCUMENTATION: "Documentación",
    API: "API",
    DOCUMENTATION_IMPORT: "Trabajando con datos",
    LOGS: "Registros",
    PROFILE: "Perfil",
    PERSONAL_NOTIFICATIONS: "Notificaciones personales",
    UNITS: "Unidades",
  },
  DASHBOARD: {
    NONE_GROUP_TYPE_WARNING:
      "Si selecciona un tipo de agrupación NONE o SECOND, es posible que haya demasiados datos, lo que puede provocar una carga prolongada o un bloqueo del navegador. Asegúrese de utilizar una cantidad de tiempo breve (un día o unas pocas horas).",
    ADD_NEW_WIDGET: "Agregar nuevo widget",
    EDIT_WIDGET: "Editar widget",
    WIDGET_OPTIONS: "Widget",
    SELECT_WIDGETS: "Seleccionar widgets",
    ADD_NEW_DEVICE: "Agregar nuevo dispositivo",
    CANCEL_SELECTION: "Cancelar selección",
    DELETE_SELECTED: "Eliminar los widgets seleccionados",
    CLEAR: "Limpiar el escritorio",
    CHOOSE_DATA_DEVICE: "Seleccione el dispositivo",
    SELECT_TYPE: "Seleccione tipo",
    SELECT_ON_MAP: "Mostrar en el mapa",
    DATA_PACKAGE: "Seleccionar paquete de datos",
    REALTIME: "Auto actualización",
    OR: "o",
    DATE_RANGE: "Rango de fechas",
    WIDGET_NAME: "Nombre del widget (opcional)",
    CHART_TYPES: {
      LINE: "Gráfico de barras",
      SCATTER: "Gráfico de dispersión",
      BUBBLE: "Gráfico de burbujas",
      TABLE: "Mesa",
      PIE: "Gráfico circular",
      RADAR: "Gráfico de pétalos",
      MAP: "Pronóstico del movimiento de masas de gas",
      GRID: "Net",
    },
    CREATE_WIDGET: "Crear widget",
    SAVE_WIDGET: "Guardar cambios",
    WIDGET: {
      LINK_COPIED_TO_CLIPBOARD: "Enlace copiado al portapapeles",
      LEAVE_FULLSCREEN: "Salir del modo de pantalla completa",
      FULLSCREEN: "Habilitar el modo de pantalla completa",
      REMOVE_WIDGET: "Quitar widget",
      EXPORT_CSV: "CSV de exportación",
      EXPORT_PNG: "PNG de exportación",
    },
  },

  AUTH: {
    FACE: {
      WELCOME_TO_NATLOGGER: "¡Bienvenido a Natlogger!",
      THE_ULTIMATE_SAAS:
        "La mejor plataforma SaaS para la exploración de la naturaleza.",
      TWENTY_TWENTY: "© 2024 Natlogger",
      PRICING: "Precios",
      BLOG: "Blog",
      CONTACTS: "Contactos",
    },
    GENERAL: {
      OR: "o",
      SUBMIT_BUTTON: "Enviar mensaje",
      NO_ACCOUNT: "¿No tienes una cuenta?",
      SIGNUP_BUTTON: "Regístrate ahora",
      FORGOT_BUTTON: "Olvidaste tu contraseña",
      BACK_BUTTON: "Regresar",
      PRIVACY: "Política de privacidad",
      LEGAL: "Términos de Uso",
      CONTACT: "Contactos",
    },
    LOGIN: {
      TITLE: "Ingrese a su cuenta",
      BUTTON: "Entrar",
      OR_SIGN_IN_WITH: "O inicie sesión con:",
      REPAIR_PASSWORD: "Restaurar contraseña",
      PASSWORD_IS_REQUIRED: "Se requiere contraseña.",
      PASSWORD: "Contraseña",
      EMAIL_IS_REQUIRED:
        "Por favor, introduce una dirección de correo electrónico válida.",
      EMAIL_ADDRESS: "Correo electrónico",
      ENTER_YOUR_USERNAME_AND_PASSWORD:
        "Introduce tu correo electrónico y contraseña",
      SIGN_IN: "Entrar",
      DONT_HAVE_AN_ACCOUNT_YET: "¿Aún no tienes una cuenta?",
      SIGN_UP: "¡Regístrate ahora!",
    },
    FORGOT: {
      TITLE: "¿Olvidaste tu contraseña?",
      DESC: "Para recuperar su contraseña, ingrese a su buzón",
      SUCCESS: "Ejecución exitosa",
    },
    REGISTER: {
      TITLE: "Regístrate ahora",
      CODE_IS_REQUIRED: "Código requerido.",
      DESC: "Ingrese sus datos para crear una cuenta",
      SUCCESS: "Su cuenta se ha registrado correctamente.",
      ALREADY_HAVE_AN_ACCOUNT: "¿Ya tienes una cuenta?",
      VERIFICATION_CODE:
        "Se ha enviado un código de confirmación al correo electrónico especificado",
      VERIFICATION_CODE_MUST_HAVE:
        "Сódigo de confirmación debe tener 6 caracteres.",
      NAME_IS_REQUIRED: "Se requiere un nombre.",
      SURNAME_IS_REQUIRED: "Apellido Necesario.",
      PASSWORD_IS_REQUIRED: "Se requiere contraseña.",
      NAME_MUST_HAVE: "El nombre no debe contener más de 35 letras.",
      SURNAME_MUST_HAVE: "El apellido no debe contener más de 35 letras.",
      EMAIL_IS_REQUIRED: "Correo electronico requerido",
      EMAIL_MUST_HAVE:
        "El correo electrónico no debe tener más de 254 caracteres.",
      PASSWORD_MUST_HAVE_LEAST:
        "La contraseña debe tener al menos 3 caracteres.",
      PASSWORD_MUST_HAVE_MOST:
        "La contraseña no debe tener más de 50 caracteres.",
      PASSWORD: "Contraseña",
      PASSWORD_CONFIRMATION_IS_REQUIRED:
        "Se requiere confirmación de contraseña",
      PASSWORD_MUST_BE_IDENTICAL: "Las contraseñas deben coincidir.",
      VALIDATION_SUCCESSFULLY:
        "La verificación se completó correctamente, espere ...",
      CODE: "Сódigo",
      VERIFICATION_CODE_PLACEHOLDER: "Código de confirmación",
    },
    REPAIR: {
      ALREADY_REMEMBER_YOUR_ACCOUNT: "¿Recuerda sus credenciales?",
      SIGN_IN: "¡Entrar!",
      PASSWORD_REPAIR: "Recuperación de contraseña",
      NEXT: "Más lejos",
      ENTER_YOUR_KNOWN_DETAILS:
        "Ingrese la información requerida para recuperar el acceso a su cuenta",
      CODE_MUST_HAVE: "Сódigo debe tener 6 caracteres.",
      CHANGE_PASSWORD: "Cambia la contraseña",
      PASSWORD_SUCCESSFULLY_CHANGED:
        "Contraseña cambiada con éxito. Por favor, registrese.",
    },
    INPUT: {
      EMAIL: "Buzón",
      FULLNAME: "Nombre completo",
      PASSWORD: "Contraseña",
      CONFIRM_PASSWORD: "Confirmación de contraseña",
      USERNAME: "Nombre de usuario",
    },
    VALIDATION: {
      NAME_REQUIRED: "Se requiere un nombre.",
      NAME_MAX_LENGTH: "El nombre no debe contener más de {length} caracteres.",
      SURNAME_REQUIRED: "Apellido Necesario.",
      SURNAME_MAX_LENGTH:
        "El apellido no debe contener más de {length} caracteres.",
      EMAIL_REQUIRED: "Correo electronico requerido.",
      EMAIL_FORMAT: "Formato correcto - user@example.com",
      EMAIL_MAX_LENGTH:
        "El correo electrónico no debe contener más de 254 caracteres.",
      PASSWORD_MIN_LENGTH:
        "La contraseña debe ser al menos {length} caracteres.",
      PASSWORD_MAX_LENGTH:
        "La contraseña no debe contener más de {length} caracteres.",
      PASSWORD_IDENTICAL: "Las contraseñas deben coincidir.",
      INVALID: "{{name}} incorrecto",
      REQUIRED: "Necesario {{name}}",
      MIN_LENGTH: "{{name}} longitud mínima {{min}} caracteres.",
      AGREEMENT_REQUIRED: "Requiere la aceptación de los Términos de uso.",
      NOT_FOUND: "Campo {{name}} extraviado",
      INVALID_LOGIN: "Ingreso invalido",
      REQUIRED_FIELD: "Campo obligatorio",
      MIN_LENGTH_FIELD: "Longitud mínima:",
      MAX_LENGTH_FIELD: "Longitud máxima:",
      INVALID_FIELD: "El valor del campo se ingresó incorrectamente",
      PLEASE_PROVIDE_CORRECT_DATA:
        "Por favor, proporcione los datos correctos.",
    },
  },
  BLOG: {
    TITLE: "Título",
    TEXT: "Texto",
    IMAGE_URL: "Enlace de imágen",
    WASTE: "Minutos para leer",
  },
  API: {
    H1: "Natlogger API",
    H2: "Descripción general",
    ABOUT:
      "Como estilo arquitectónico de interacción con la API de Natlogger, se utiliza un enfoque REST, que le permitirá operar cómodamente con datos.",
    H3: "¿Qué es REST?",
    REST:
      "REST (restful) son los principios generales para organizar la interacción de una aplicación / sitio con un servidor utilizando el protocolo HTTP. La peculiaridad de REST es" +
      "que el servidor no recuerda el estado del usuario entre solicitudes - cada solicitud contiene información que identifica al usuario (por ejemplo, un token) y todos los parámetros necesarios para realizar la operación.",
    READ_MORE: "Puede leer más sobre la arquitectura REST",
    HERE: "aquí",
    DATA: "Datos para interactuar con la API de Natlogger",
    HOST: "Host para acceso a API",
    TOKEN: "Simbólico",
    TOKEN_NOT_AVAILABLE: "No disponible para su tarifa",
    TOKEN_DES: "poner el Token en el Encabezado de la solicitud.",
    TEAM: "Tu ID de equipo",
    DEFINES: "Datos adicionales para consultas",
    GROUP_TYPE: "Agrupación de datos",
    REAL_TIME_RANGE: "Intervalo de tiempo (en horas)",
    SHOW: "Mostrar",
    GROUP: {
      NONE: "Sin agrupamiento",
      AUTO: "Autoajuste según el período de tiempo",
      SECOND: "Segundo",
      MINUTE: "Minuto",
      HOUR: "Hora",
      DAY: "Día",
      WEEK: "Una semana",
      MONTH: "Mes",
      YEAR: "Año",
    },
    METHODS: "Métodos para interactuar con la API de Natlogger",
    PARAMETERS: "Obtener una lista de parámetros",
    DEVICES: "Obtener una lista de dispositivos",
    MEASURE: "Recibiendo información",
    CIRCLE: "Recuperar datos agrupados por valor",
    CSV: "Obtener datos sin procesar como CSV",
    MAIN: "El principal",
    HTTP: "Método HTTP:",
    JSON: "La solicitud devuelve una respuesta en formato json",
    CSV_FILE: "La solicitud devuelve una respuesta como un archivo csv.",
    REQUEST_PARAMS: "Solicitar parámetros",
    REQUEST: "Investigación",
    RESPONSE: "Responder",
    EXAMPLE: "Ejemplo de uso",
    REQUIRED: "Obligatorio",
    OPTIONAL: "Opcional",
    UNIT_ID: "Unidades",
    CALCULATE: "Calcule el número total de registros en la base de datos",
    ENRICH: "Enriquecimiento con datos de referencia",
    COUNT: "Número de elementos recuperados",
    START: "Elemento de inicio de selección",
    SORT_FIELD: "Campo de clasificación",
    SORT_DIR: "Dirección de clasificación",
    NAME: "Nombre del parámetro",
    DEVICE_TYPE_IDS: "Parámetros para el muestreo como un conjunto de sus ID",
    PARAM_IDS: "Parámetros para el muestreo como un conjunto de sus ID",
    DEVICE_IDS: "Dispositivos que se muestrearán como un conjunto de sus ID",
    DATA_PACKAGE_ID: "ID del paquete de datos para muestrear",
    PUBLIC: "Público",
    LATITUDE: "Latitud del punto",
    LONGITUDE: "Longitud del punto",
    RADIUS: "Radio de búsqueda",
    START_DATE: "Fecha de inicio de la muestra",
    END_DATE: "Fecha de finalización de la muestra",
    GROUP_TYPES: "Agrupación de datos",
    ROUND: "Redondeo",
  },
  DOCUMENTATION_IMPORT: {
    WORK: "Trabajando con datos",
    IMPORT: "Importación de datos",
    IMPORT_LI1: "Preparar un archivo",
    IMPORT_EXAMPLE1: "Archivo de muestra: ",
    IMPORT_EXAMPLE2: "Documentación de protocolo: ",
    IMPORT_LI2: "Crea una nueva estación meteorológica",
    IMPORT_LI3: "Cargar archivo preparado mediante la ",
    SOKOL: "Cargando datos de SokolMeteo",
    SOKOL_LI1:
      "Descargue datos de SokolMeteo para la estación meteorológica que le interese",
    SOKOL_LI2: "Crea una nueva estación meteorológica",
    SOKOL_LI3: "Cargar archivo preparado mediante la importación de datos",
  },
  PROFILE: {
    ACCOUNT: "Mi cuenta",
    CHANGE: "Cambiar foto",
    UNDO: "Cancelar cambios",
    DELETE: "Borrar foto",
    ABOUT: "El principal",
    NAME: "Nombre",
    NAME_ENTER: "Introduzca su nombre",
    NAME_REQUIRED: "Se requiere un nombre.",
    NAME_MAX_LENGTH: "El nombre no debe tener más de 35 caracteres.",
    SURNAME: "Apellido",
    SURNAME_ENTER: "Ingrese su apellido",
    SURNAME_REQUIRED: "Apellido Necesario.",
    SURNAME_MAX_LENGTH: "El apellido no debe tener más de 35 caracteres.",
    DEFAULT_CHANEL: "Canal de notificación predeterminado", // todo: не придумал как перевести
    EMAIL: "Correo electrónico",
    EMAIL_ENTER: "Introduce tu correo electrónico",
    EMAIL_REQUIRED: "Correo electronico requerido.",
    EMAIL_FORMAT: "Formato correcto - user@example.com",
    EMAIL_MAX_LENGTH:
      "El correo electrónico no debe tener más de 254 caracteres.",
    PHONE: "Teléfono",
    PHONE_ENTER: "Ingresa tu teléfono",
    TELEGRAM: "Telegram ID",
    TELEGRAM_ENTER: "Ingrese su ID de telegrama",
    SECURITY: "La seguridad",
    PASSWORD: "Contraseña",
    PASSWORD_ENTER: "Ingresa tu contraseña",
    PASSWORD_CONFIRMATION: "Confirmación de contraseña",
    PASSWORD_CONFIRMATION_ENTER: "Confirmar la contraseña",
    PASSWORD_IDENTICAL: "Las contraseñas deben coincidir.",
    SAVE: "Guardar cambios",
  },
  LOGS: {
    CREATED: "Fecha de solicitud",
    USER_ID: "ID de usuario",
    METHOD: "Método",
  },
  MULTI_SG: {
    CHOOSE: "Por favor seleccione",
    SELECTED: "Seleccionado",
  },
  WIDGET: {
    SELECT_ALL_PARAMETERS: "Seleccionar todas las opciones",
    PARAMETERS: "Parámetros",
  },
  UNIT: {
    NAME: "Nombre",
    NAME_EN: "Nombre (Inglés)",
    NAME_RU: "Nombre (Ruso)",
    NAME_ES: "Nombre (Español)",
    CODE: "Designación",
    CODE_NOT_SET: "Designación no establecida",
  },
  DATA_SHOP: {
    VOTES: "{votes} votos",
    NO_VOTES: "Sin votos",
    RATING: "Clasificación: {rating}/{votes}",
    DATA_COLLECTION_POINTS_COUNT: "Recuento de puntos de recogida de datos",
    DATA_COLLECTION_PERIOD: "Periodo de recogida de datos",
    OTHER_DATA_PACKAGES: "Otros paquetes de datos",
    DATA_COLLECTION_POINTS_ON_MAP: "Puntos de recogida de datos en el mapa",
    SHOW_ON_MAP: "Mostrar en el mapa",
    DATA_PACKAGES: "Paquetes de datos",
    SHOW: "Espectáculo",
    VOTE: "Revisar",
    VOTE_MODAL_HEADER: "Revisar",
    VOTING_SUCCESS: "Gracias por tus comentarios",
    VOTING_THANK_YOU: "Gracias",
    ML_MODEL_ALLOWED_PARAMETERS: "Compatible con parámetros",
    ML_MODEL_TYPE: "Tipo de modelo",
    MODELS: "Modelos",
    OTHER_MODELS: "Otros modelos",
  },
  pricing: {
    buy: "Comprar",
    unlimited: "Ilimitado",
    none: "No",
    yes: "Si",
    up: "Hasta",
    individual: "Individualmente",
    days: "dias",
    month: "/mes",
    year: "/año",
    reg: "Regístrate ahora",
    call: "Para contactarnos",
    desc: "En la tabla, puede ver las capacidades, los conjuntos de funciones y los precios de la plataforma para diferentes tipos de usuarios",
    choose: "Elige una tarifa",
    head: {
      leader: "TOMADOR DE DECISION",
      analyst: "ANALISTA TECH",
      consultant: "CONSULTOR PRO",
      special: "PERSONALIZADO",
    },
    row1: {
      head: "Número de miembros del equipo",
      analyst: {
        desc: "Usuario adicional $12",
      },
      consultant: {
        desc: "Usuario adicional $12",
      },
    },
    row2: {
      head: "Numero de dispositivos",
      analyst: {
        desc: "Dispositivo adicional $12",
      },
      consultant: {
        desc: "Dispositivo adicional $12",
      },
    },
    row3: {
      head: "Período de prueba gratuito",
    },
    row4: {
      head: "Soporte técnico",
      analyst: {
        text: "24/7",
        desc: "E-mail, Skype",
      },
      consultant: {
        text: "24/7",
        desc: "E-mail, Skype, WhatsApp, Telegram, TeamViewer",
      },
      special: "Personal 24/7",
    },
    row5: {
      head: "Personalización del servicio a través del logo del usuario",
    },
    row6: {
      head: "Fuentes de datos persistentes",
      leader: "Solo tus dispositivos",
      analyst: "Solo tus dispositivos",
      consultant: "Los dispositivos de su cliente final + API",
      special: "Individualmente + API + AI",
    },
    row7: {
      head: "Generando un informe",
      leader: "solo lectura",
    },
    row8: {
      head: "Registro de visitas de usuarios",
    },
    row9: {
      head: "Limitación de visitas por IP",
    },
    row10: {
      head: "Acuerdo de nivel de servicio",
    },
    row11: {
      head: "Características clave",
      el1: "Trabaje con datos listos para evaluación en cuadros de mando, gráficos e informes",
      el2: "Observación y toma de decisiones",
      el3: "Pasar conjuntos de datos por referencia",
      el4: "Instrucciones para ANALISTA TECH y CONSULTOR PRO",
      el5: "Conectando dispositivos a la plataforma",
      el6: "Formación de un equipo de ANALISTA TECH y TOMADOR DE DECISION",
      el7: "Cargue y procese sus propios conjuntos de datos en la plataforma.",
      el8: "Trabajar con sus propios datos sin procesar, limpios y sin huecos (small data)",
      el9: "Establecer sus propios parámetros de límite",
      el10: "Configurar sus propias alertas",
      el11: "Preparar y enviar informes",
      el12: "Preparando datos para TOMADOR DE DECISION",
      el13: "Personalización de la plataforma usando su logo",
      el14: "Obtención de conjuntos de datos disponibles a través de API",
      el15: "Casi todo lo que necesita para hacer negocios, investigar o enseñar",

      special: {
        el1: "Integración con CRM",
        el2: "Conjuntos de datos divididos por: país, actual, pronóstico, histórico, pronóstico histórico",
        el3: "Imágenes de satélite",
        el4: "Tarjetas",
        el5: "Modelos",
        el6: "AI",
      },
    },
    row12: {
      head: "Acceso a la API",
      consultant: {
        el1: "Matrices de datos",
      },
      special: {
        el1: "Usuarios con requerimientos especiales",
        el2: "Organizaciones e instituciones que requieren sistemas y operaciones locales o cerrados en grandes centros de gestión",
        el3: "Los organismos gubernamentales",
        el4: "Grandes empresas industriales",
        el5: "Corporaciones de consultoría",
      },
    },
    row13: {
      head: "Quién necesita",
      leader: {
        el1: "Tomadores de decisiones",
        el2: "Líderes",
        el3: "Gerentes",
        el4: "Inspectores",
        el5: "Supervisores",
      },
      analyst: {
        el1: "Empresas independientes que utilizan la plataforma solo para sus propias necesidades",
        el2: "Pequeñas y medianas empresas industriales",
        el3: "Vivienda y servicios comunales, sociedades gestoras y asociaciones de propietarios",
        el4: "Arquitectos",
        el5: "Constructores",
        el6: "Agrónomos",
        el7: "Ecologistas",
        el8: "Ingenieros de seguridad, salud y medio ambiente",
        el9: "Analistas y estadísticos de plantilla",
        el10: "Especialistas en teledetección y detección",
        el11: "Especialistas operativos dedicados de la industria",
        el12: "Empresas mineras",
        el13: "Empresas del sector vial",
        el14: "Círculos escolares sobre ecología, protección del medio ambiente, cambio climático, desarrollo sostenible e Internet de las cosas",
      },
      consultant: {
        el1: "Consultores y empresas consultoras en el campo de la ecología, protección del medio ambiente y gestión de riesgos, así como laboratorios, que cuentan con sus propios clientes finales bajo el plan TOMADOR DE DECISION",
        el2: "Empresas que prestan servicios de telemática ambiental / telemática ambiental",
        el3: "Empresas especializadas en monitorear el medio ambiente (incluyendo aire, agua, suelo, vegetación, clima y clima, entre otros) a partir de imágenes satelitales para refinar sus datos usando una red de sensores terrestres",
        el4: "Científicos, docentes y docentes que trabajan con un gran número de alumnos realizando prácticas / labores de laboratorio según el plan ANALISTA TECH",
        el5: "Científicos de datos y profesionales de big data",
      },
      special: {
        el1: "Usuarios con requisitos especiales",
        el2: "Organizaciones e instituciones que necesitan sistemas locales o cerrados y operación en grandes centros de despacho",
        el3: "Estructuras estatales",
        el4: "Grandes empresas industriales",
        el5: "Empresas consultoras",
      },
    },
  },
};
