// mutation types
export const SET_ACTIONS = "setActions";

export default {
  state: {
    actions: [],
  },
  getters: {
    /**
     * Get list of breadcrumbs for current page
     * @param state
     * @returns {*}
     */
    actions(state) {
      return state.actions;
    },
  },
  actions: {
    /**
     * Set the actions list
     * @param state
     * @param payload
     */
    [SET_ACTIONS](state, payload) {
      state.commit(SET_ACTIONS, payload);
    },
  },
  mutations: {
    [SET_ACTIONS](state, payload) {
      state.actions = payload;
    },
  },
};
