import ApiService from "@/core/services/api.service";
import * as _ from "lodash";
import Vue from "vue";

export async function loadSettings(teamId) {
  const path = "/platform/api/setting/" + teamId + "_dashboard";
  const { data } = await ApiService.query(path);
  if (_.get(data, "value")) {
    return JSON.parse(data.value);
  }

  return null;
}

export async function storeSettings(data) {
  const path = "/platform/api/setting/" + ApiService.teamId + "_dashboard";

  try {
    await ApiService.post(path + "/save", { value: JSON.stringify(data) });
  } catch (e) {
    Vue["$log"].error(e);
  }
}
