import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";
import i18nService from "@/core/services/i18n.service.js";
import {
  Actions as ActionsDashboard,
  Actions,
} from "@/core/services/store/dashboard/dashboard.module";
import Vue from "vue";

// action types
export const VERIFY_AUTH = "verifyAuth";
export const LOGIN = "login";
export const LOGOUT = "logout";
export const REGISTER = "register";
export const UPDATE_USER = "updateUser";

// mutation types
export const PURGE_AUTH = "logOut";
export const SET_AUTH = "setUser";
export const SET_ERROR = "setError";
export const SET_AVATAR = "setAvatar";
export const SET_USERNAME = "setUsername";
export const SET_LOGO = "setLogo";

const state = {
  errors: null,
  logo: null,
  user: {},
  isAuthenticated: !!JwtService.getToken(),
};

const getters = {
  currentUser(state) {
    return state.user;
  },
  isAuthenticated(state) {
    return state.isAuthenticated;
  },
  teamLogo(state) {
    return state.logo;
  },
};

const actions = {
  [LOGIN](context, credentials) {
    return new Promise((resolve, reject) => {
      ApiService.post("/platform/api/auth/token/sign-in", credentials)
        .then(({ data }) => {
          JwtService.saveToken(data.token);
          context.commit(SET_AUTH, {}); // set dummy user for now
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, [response.data.message]);
          reject(response);
        });
    });
  },
  [LOGOUT](context) {
    const dto = { token: JwtService.getToken() };
    // no token - just purge auth locally
    if (
      !dto ||
      !dto.token ||
      dto.token.length === 0 ||
      dto.token === "undefined"
    )
      return new Promise((resolve) => {
        context.commit(PURGE_AUTH);
        resolve();
      });

    return new Promise((resolve, reject) => {
      ApiService.post("/platform/api/auth/token/sign-out", dto)
        .then(({ data }) => {
          context.commit(PURGE_AUTH);
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, [response.data.message]);
          reject(response);
        });
    });
  },
  [REGISTER](context, credentials) {
    return new Promise((resolve, reject) => {
      ApiService.post("/platform/api/user/sign-up", credentials)
        .then(({ data }) => {
          context.commit(SET_AUTH, data);
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, [response.data.message]);
          reject(response);
        });
    });
  },

  async [VERIFY_AUTH](context) {
    let token = JwtService.getToken();
    if (token && token.length !== 0 && token !== "undefined") {
      try {
        ApiService.setHeader();
        const { data } = await ApiService.query("/platform/api/auth/info");
        const mySet = new Set();
        for (let i = 0; i < data.grants.length; i++) {
          mySet.add(data.grants[i]);
        }
        data["grantsSet"] = mySet;
        context.commit(SET_AUTH, data);

        // load team after success auth.
        const teamId = await ApiService.loadTeamID();
        if (
          data.locale == null ||
          data.locale != i18nService.getActiveLanguage()
        ) {
          i18nService.setBackendLanguage(
            i18nService.getActiveLanguage(),
            false
          );
        }

        // load dashboard
        context.dispatch(ActionsDashboard.LoadSettings, { teamId });
      } catch (e) {
        context.commit(PURGE_AUTH);
      }
    } else {
      context.commit(PURGE_AUTH);
    }
  },
  [UPDATE_USER](context, payload) {
    const { email, username, password, image, bio } = payload;
    const user = { email, username, bio, image };
    if (password) {
      user.password = password;
    }

    return ApiService.put("user", user).then(({ data }) => {
      context.commit(SET_AUTH, data);
      return data;
    });
  },
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_AUTH](state, user) {
    state.isAuthenticated = true;
    state.user = user;
    state.errors = {};
    //JwtService.saveToken(state.user.token);
  },
  [PURGE_AUTH](state) {
    state.isAuthenticated = false;
    state.user = {};
    state.errors = {};
    JwtService.destroyToken();
  },
  [SET_AVATAR](state, url) {
    state.user.fields.avatar = url;
  },
  [SET_USERNAME](state, username) {
    state.user.fields.name = username.name;
    state.user.fields.surname = username.surname;
    state.user.formattedUserName = username.fullname;
  },
  [SET_LOGO](state, url) {
    state.logo = url;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
