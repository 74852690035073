// dashboard module store
import * as _ from "lodash";
import Vue from "vue";
import {
  copyWidget,
  createWidget,
  createExistingWidget,
} from "./dashboard.model";
import ApiService from "@/core/services/api.service";
import { GridSettings } from "@/core/services/store/dashboard/dashboard.model";
import {
  loadSettings,
  storeSettings,
} from "@/core/services/store/dashboard/dashboard.api";

export const Actions = {
  SaveWidget: "SaveWidget",
  EditWidget: "EditWidget",
  RemoveWidget: "RemoveWidget",
  ShareWidget: "ShareWidget",
  LoadSettings: "LoadDashboardSettings",

  InsertExternalWidget: "InsertExternalWidget",
};

export const Mutations = {
  AddNewWidget: "AddNewWidget",
  ReplaceWidget: "ReplaceWidget",
  RemoveWidget: "RemoveWidget",
  LoadedDashboardSettings: "LoadedDashboardSettings",

  UpdateSettings: "UpdateSettings",
};

const actions = {
  [Actions.SaveWidget](context, payload) {
    const newWidget = createWidget(payload.type, payload.params);
    Vue["$log"].debug(newWidget);
    context.commit(Mutations.AddNewWidget, newWidget);
  },

  [Actions.EditWidget](context, payload) {
    const newWidget = createExistingWidget(payload);
    Vue["$log"].debug(newWidget);
    context.commit(Mutations.ReplaceWidget, newWidget);
  },

  [Actions.RemoveWidget](context, id) {
    context.commit(Mutations.RemoveWidget, id);
  },

  [Actions.ShareWidget]({ state }, id) {
    return _.find(state.settings.widgets, (w) => w.i == id);
  },

  // загружаем из настроек конфиграцию для DashBoard
  async [Actions.LoadSettings](context, { teamId }) {
    if (context.state.loaded) {
      return;
    }
    try {
      const settings = await loadSettings(teamId);
      context.commit(Mutations.LoadedDashboardSettings, { settings });
    } catch (e) {
      Vue["$log"].debug("error", e);
    }
  },

 async [Actions.InsertExternalWidget](context, { widget, teamId }) {
    console.log("Inserting external widget:", widget, teamId);
    const settings = await loadSettings(teamId).catch(() => {});
    const set = new GridSettings(settings);
    set.widgets.unshift(copyWidget(widget));
    context.commit(Mutations.UpdateSettings, { settings: set });
  },
};

const mutations = {
  [Mutations.RemoveWidget](state, id) {
    state.settings.widgets = state.settings.widgets.filter((w) => w.i != id);
  },

  [Mutations.AddNewWidget](state, widget) {
    state.settings.widgets.push(widget);
  },

  [Mutations.ReplaceWidget](state, widget) {
    const item = state.settings.widgets.find((item) => item.i === widget.i);
    Object.assign(item, widget);
  },

  [Mutations.UpdateSettings](state, { settings }) {
    console.log("Updating settings:", settings);
    state.settings = settings;
    // storeSettings(state.settings);
  },

  [Mutations.LoadedDashboardSettings](state, { settings }) {
    state.settings = new GridSettings(settings);
    state.loaded = true;
  },
};

export const updateSettingsStore = _.debounce(async (data) => {
  console.log("Updating settings debounce:", data);
  // await storeSettings(data);
}, 1500);

export default {
  state: {
    loaded: false,
    settings: new GridSettings(),
  },
  mutations,
  actions,
};

