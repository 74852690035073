// mutation types
export const SET_QUICK_ACTION = "setQuickAction";

export default {
  state: {
    quickAction: null,
  },
  getters: {
    /**
     * Get list of breadcrumbs for current page
     * @param state
     * @returns {*}
     */
    quickAction(state) {
      return state.quickAction;
    },
  },
  actions: {
    /**
     * Set the actions list
     * @param state
     * @param payload
     */
    [SET_QUICK_ACTION](state, payload) {
      state.commit(SET_QUICK_ACTION, payload);
    },
  },
  mutations: {
    [SET_QUICK_ACTION](state, payload) {
      state.quickAction = payload;
    },
  },
};
