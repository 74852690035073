const gen = () => (Math.random() * Date.now()).toString(16);

// generate uuid4
export function uuid4() {
  return gen() + "-" + gen() + "-" + gen() + "-" + gen();
}

export function uuidv4() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c == "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}
