// USA
export const locale = {
  TRANSLATOR: {
    SELECT: "Select your language",
    LANGUAGE: "Language",
  },
  COMMON: {
    "YES": "Yes",
    "NO": "No",
    HELP: "Help",
    GO: "Go",
    AUTHOR: "Author",
    REPLACE: "Replace",
    BROWSE_FILE: "Browse...",
    DOCUMENT_UPLOADED: "Document uploaded",
    DOWNLOAD: "Download",
    ACTIONS: "Actions",
    ACTIVE: "Active",
    ADD: "Add",
    ALL_DEVICES: "All devices",
    ALTITUDE: "Altitude",
    APPLY: "Apply",
    ARE_YOU_SURE: "Are you sure?",
    ARE_YOU_SURE_WANT_TO_CANCEL:
      "Are you sure want to cancel subscription %{name}?",
    ARE_YOU_SURE_YOU_WANT_TO_CANCEL: "Are you sure you want to cancel %{name}?",
    ARE_YOU_SURE_YOU_WANT_TO_CHANGE:
      "Are you sure you want to change tariff to %{name}?",
    ARE_YOU_SURE_YOU_WANT_TO_DELETE: "Are you sure you want to delete %{name}?",
    ARE_YOU_SURE_YOU_WANT_TO_RESTORE:
      "Are you sure you want to restore %{name}?",
    BACK: "Back",
    BEGIN_DATE: "Begin date",
    CANCEL: "Cancel",
    CANCELED_SUCCESSFULLY: "Canceled successfully",
    CATALOGUE: "Group of devices",
    CHANGE: "Change",
    CHANGED: "Changed",
    CHOOSE_FILE_OR_DROP: "Choose a file or drop it here...",
    CLEAR: "Clear",
    CLOSE: "Close",
    CODE: "Code",
    COMMAND: "Command",
    CONFIRMATION: "Confirmation",
    CREATE: "Create",
    CREATED: "Created",
    DELETED_SUCCESSFULLY: "Deleted successfully",
    DESCRIPTION: "Description",
    DEVICE: "Device",
    DROP_FILE_HERE: "Drop file here...",
    EMPTY_DATA: "No data matching the specified conditions",
    END_DATE: "End date",
    ERROR: "Error",
    EXAMPLE: "Example",
    FILE: "File",
    HOURS: "Hours",
    INFO: "Info",
    LATITUDE: "Latitude",
    LONGITUDE: "Longitude",
    MAIN: "Main",
    MAX_VALUE: "Max value",
    MIN_VALUE: "Min value",
    MINUTES: "Minutes",
    NAME: "Name",
    NEXT: "Next",
    NEXT_STEP: "Next step",
    NO_DATE: "No date selected",
    NO_TIME: "No time selected",
    PARAMETERS: "Parameters",
    PRICE: "Price",
    SAVE: "Save",
    SAVED_SUCCESSFULLY: "Saved successfully",
    SEARCH: "Search",
    SELECTED: "Selected",
    SELECTED_DEVICES: "Selected devices",
    SHARE: "Share",
    SHOW_BY: "Show by",
    START_DATE: "Start date",
    SUCCESS: "Success",
    TAB_MAIN: "Main",
    TEAM: "Team",
    THIS_FIELD_IS_REQUIRED: "This field is required",
    TITLE: "Title",
    TRIAL: "Trial until",
    TYPE: "Type",
    TYPE_CATALOGUE: "Type catalogue",
    TYPE_DEVICE: "Type device",
    UNKNOWN_ERROR: "Unknown error",
    USER: "User",
    WARNING: "Warning",
    PLEASE_WAIT: "Please, wait...",
  },
  COUNTRIES: {
    COUNTRY: "Country",
    COUNTRIES: "Countries",
  },
  ACTIONS: {
    CREATE_NEW: "Create new",
    EDIT: "Edit",
    DELETE: "Delete",
    RELOAD: "Reload",
    IMPORT_DATA: "Import data",
    IMPORT: "Import",
    ADD: "Add",
    VIEW: "View",
    EXECUTE: "Execute",
    SHOW: "Show",
  },
  MAP: {
    SELECT_COORDS_AND_RADIUS: "Select coordinates and radius",
    CHANGE: "Change",
    CHANGE_POINT_AND_RADIUS: "Change point and radius",
    RESET: "reset",
  },
  MONITORING: {
    "TYPE": "Device Type",
    "TEAM": "Team",
    "IMEI": "IMEI",
    "NO_DATA": "No Data",
    "ACTIVE": "Active",
    "LAST_DATA_RECEIVED": "Last Data Received",
    "PARAMETERS": "Parameters",
    ORDER: "Order",
    ADD_NEW_DEVICE: "Add new Device",
    REALLY_BUY: "Are you really want to buy %{name}?",
    SUCCESS_CREATED_ORDER:
      "Order created successfully. Go to Team Orders to make a payment.",
    NO_CATALOGUE_SELECTED: "No group of device selected",
    MODAL: {
      APPLY: "Apply",
      SELECT:
        "Please select your tariff to open access to the full functionality of the platform and start solving your professional tasks",
      INPUT: "If you have a promocode, enter it here",
      INPUT_PLACEHOLDER: "Promocode",
    },
  },
  FEEDBACK: {
    SEND_FEEDBACK: "Send Feedback",
    DESCRIPTION_PROBLEM: "Describe your problem or feedback here",
    SCREENSHOT: "Screenshot",
    MAKE_SCREENSHOT: "Click here to make screenshot",
    SUBMIT: "Submit",
    SELECT_RECTANGLE: "Select rectangle to crop screenshot",
    FEEDBACK_SENT: "Feedback sent!",
  },
  DEVICE: {
    COMMANDS_NOT_FOUND: "No commands found for this Device",
    IMEI: "IMEI",
    EXECUTE_DEVICE_COMMAND: "Execute device command",
    SELECT_COMMAND: "-- Select command --",
    OTHER_TEAM_PUBLIC_DEVICE:
      "This is a other-team public device - you can interact it only in read-only mode. You can create yourself device with full access.",
    TYPE_DEVICE_TYPE_NAME: "Type device type name",
    PHONE_NUMBER: "Phone number",
    CHOOSE_COORDINATES: "Choose coordinates",
    PUBLIC: "Public",
    PARAMETER_MAPPING: "Parameter mapping",
    ADDITIONAL_DEVICES: "Additional devices",
    PARAMETER_ADD_INFO:
      "You can add new parameters for your device. You do not need to specify the next set of parameters manually, because it was configured automatically:",
    ADD_PARAMETER_MAPPING: "Add parameter mapping",
    EDIT_PARAMETER_MAPPING: "Edit parameter mapping",
    TRANSLATE_EXPRESSION: "Translate expression",
    DEVICE_TYPE: "Device type",
    IMPORT_DEVICE_DATA: "Import device data",
    LAST_DATA_RECEIVED: "Last data received date",
    CREATE_NEW_DEVICE: "Create new Device",
    PARAMETER: "Parameter",
    EDIT_DEVICE: "Edit Device",
    /*Device types*/
    LOCATION_MANUAL: "Location manual",
    INTEGRATION: "Integration",
    TRUST_LEVEL: "Trust level",
    AMEND_TIMEZONE: "Amend timezone",
    COMMANDS: "Commands",
    ADD_COMMAND: "Add command",
    EDIT_COMMAND: "Edit command",
    VALUE: "Value",
    ADD_PROPERTY: "Add property",
    CODE: "Code",
    CREATE_NEW_DEVICE_TYPE: "Create new Device Type",
    EDIT_DEVICE_TYPE: "Edit Device Type",
    SELECT_DEVICE_TYPE: "-- Select device type --",
    DEFAULT_VALUE: "Default value",
    DEVICE: "Device",
    DEVICES: "Devices",
    IMPORT_COMPLETED: "Import completed",
    IMPORT_FAILED: "Import failed",
    IMPORT_FAILED: "Export failed",
    DEVICE_FIELDS: "Additional options",
    FIELD_ERROR: "Incorrect value of an additional field",
    REMOVE_COMPOUND: "Remove parameters",
    ADD_COMPOUND: "Add parameters",
    COMPOUND_ENTRY: "Span parameters",
    CHOOSE_COMPOUND: "Select a number",
    COMPOUND_PROMPT: "Select a number from the list",
    COMPOUND_GROUP_PROMPT: "Select a group from the list",
    SPAN: "Span",
    SPAN1: "Test", // TODO: Это тестовая строка
    GRIDNUM: "Net",
    SENSORNUM: "Sensor",
  },
  CATALOGUE: {
    NAME: "Name",
    DESCRIPTION: "Description",
    DEVICES: "Devices",
    /*CHOOSE_DEVICE: "Choose device",
    SELECTED_DEVICES: "Selected devices",*/
    CREATE_NEW_CATALOGUE: "Create new Group of devices",
    EDIT_CATALOGUE: "Edit Group of devices",
  },
  MESSAGES: {
    MEASURED: "Measured",
    DEVICE: "Device",
    MESSAGE_TYPE: "Message type",
    DATA: "Data",
    SELECT_MESSAGE_TYPE: "-- Select message type --",
    UNIQUE_ID: "Unique id",
  },
  PARAMETERS: {
    TYPE_TEAM_NAME: "Type team name",
    SELECT_UNIT: "-- Select unit --",
    NAME: "Name",
    DESCRIPTION: "Description",
    CODE: "Code",
    UNIT: "Unit",
    GLOBAL: "Global",
    CIRCLE: "Circle",
    CONCENTRATION: "Concentration",
    CALCULATED: "Calculated",
    CALCULATE_EXPRESSION: "Calculate expression",
    ATTACHMENT: "Attachment",
    CREATE_NEW_PARAMETER: "Create new parameter",
    EDIT_PARAMETER: "Edit parameter",
    ALERT:
      "This is a global parameter - you can interact it only in read-only\n" +
      "              mode. You can create yourself parameter with full access.",
    PARAMETER: "Parameter",
    PARAMETERS: "Parameters",
  },
  DATA_PACKAGES: {
    RADIUS: "Radius",
    PARAMETERS: "Parameters",
    CREATE_NEW_DATA_PACKAGE: "Create new Data Package",
    EDIT_DATA_PACKAGE: "Edit Data Package",
    NO_DEVICE_FOUND: "No device found in selected area",
    PREVIEW_IMAGE: "Shop preview image",
  },
  ACCOUNTS: {
    SELECT_ACCOUNT_TYPE: "-- Select account type --",
    SELECT_CURRENCY: "-- Select currency --",
    CURRENCY: "Currency",
    BALANCE: "Balance",
    EXTERNAL_ID: "External Id",
    TEAM_NAME: "Team name",
  },
  TRANSACTIONS: {
    SELECT_TRANSACTION_TYPE: "-- Select transaction type --",
    TYPE_USER_NAME: "Type user name",
    AMOUNT: "Amount",
    BALANCE_BEFORE: "Balance before",
    BALANCE_AFTER: "Balance after",
    TRANSACTION_TYPE: "Transaction type",
    ACCOUNT_EXTERNAL_ID: "Account External ID",
  },
  PAYMENTS: {
    SELECT_PAYMENT_TYPE: "-- Select payment type --",
    SELECT_PAYMENT_SYSTEM: "-- Select payment system --",
    SELECT_PAYMENT_STATE: "-- Select payment state --",
    PAYMENT_SYSTEM: "Payment system",
    PAYMENT_STATE: "Payment state",
    FEE: "Fee",
    REGISTERED_AMOUNT: "Registered amount",
    TOTAL_AMOUNT: "Total amount",
    CANCEL_PAYMENT: "Cancel payment",
    RESTORE_PAYMENT: "Restore payment",
    ACCOUNT: "Account",
    TYPE_ACCOUNT: "Type account",
    EXTERNAL_IDENTIFIER: "External identifier",
    PAYMENT_UID: "Payment UID",
    CREATE_NEW_PAYMENT: "Create new payment",
    PAYMENT_SUCCESSFUL: "Payment successful!",
    PAYMENT_ERROR: "Payment error",
    PAYMENT_THANK_YOU:
      "Thank you for your payment. An automated payment receipt will be sent to your e-mail.",
    PAYMENT_ERROR_MESSAGE:
      "There was a problem processing your payment. Please try again.",
    PAYMENT_GO_TO_MY_TEAM: "To the Team page",
  },
  PRODUCTS: {
    SELECT_PRODUCT_TYPE: "-- Select product type --",
    PRODUCT_TYPE_NAME: "Product Type Name",
    PRICE: "Price",
    REFERENCE: "Reference",
    PRIMARY: "Primary",
    OWNERSHIP: "Ownership",
    LIFETIME: "Lifetime (msec)",
    CHOOSE_PRODUCT: "Choose product",
    CREATE_NEW_PRODUCT: "Create new Product",
    EDIT_PRODUCT: "Edit Product",
    DEFERMENT: "Deferment (msec)",
  },
  DISCOUNTS: {
    DISCOUNT_TYPE_NAME: "Discount Type Name",
    REUSABLE: "Reusable",
    SELECT_DISCOUNT_TYPE: "-- Select discount type --",
    SECRET_CODE: "Secret Code",
    ONCE_FOR_USER: "Once for User",
    CREATE_NEW_DISCOUNT: "Create new Discount",
    EDIT_DISCOUNT: "Edit Discount",
  },
  ORDERS: {
    SELECT_ORDER_TYPE: "-- Select order type --",
    USER_NAME: "User Name",
    DISCOUNT_NAME: "Discount Name",
    TARGET_CURRENCY_NAME: "Target Currency Name",
    PAYMENT_SYSTEM_NAME: "Payment System Name",
    ORDER_STATE_NAME: "Order State Name",
    DELETE_ORDER: "Delete order",
    CANCEL_ORDER: "Cancel order",
    ORDER_STATE: "Order state",
    DISCOUNT: "Discount",
    PAYMENT_SYSTEM: "Payment system",
    DISCOUNT_AMOUNT: "Discount amount",
    PAID: "Paid",
    VIEW_ORDER: "VIew Order",
  },
  PAYMENT_INFO: {
    ABOUT_PAYMENT_RULES: "Rules of payment",
    PAYMENT_RULES_HEADER:
      "Rules of payment and the security of online payments, confidentiality of the information",
    PAYMENT_RULES_CONTENT_1:
      "Online payments via bank cards is carried out through JSC “Alpha-Bank” (Public Joint Stock Company).",
    // eslint-disable-next-line max-len
    PAYMENT_RULES_CONTENT_2:
      "The payment service via the Internet is carried out in accordance with the Rules of the international payment systems Visa, MasterCard on the principles of confidentiality and security of the payment, using the most modern methods of verification, encryption and data transmission over closed communication channels. The bank card data is entered on the secure payment page of Alpha-Bank JSC.",
    // eslint-disable-next-line max-len
    PAYMENT_RULES_CONTENT_3:
      "On the bank card data entry page, you will need to enter the bank card data: card number, cardholder name, expiry date, three-digit security code (CVV2 for VISA, CVC2 for MasterCard). All the necessary data are printed on the card itself. The three-digit security code is the three digits on the back of the card.",
    PAYMENT_RULES_CONTENT_4:
      "Next, you will be redirected to your Bank’s page to enter the security code (3D-Secure code), that will be sent to you via SMS. If you do not receive the security code, you should contact the Bank that issued your card.",
    CASES_TO_REFUSAL: "Cases when your Bank can decline the payment:",
    REFUSAL_CASE_1:
      "your bank card is not designed for making payments via the Internet, this you can find out by contacting your Bank;",
    REFUSAL_CASE_2:
      "due to insufficient funds on the bank card. You can contact the bank that issued the card and find out more information about the availability of funds on your bank card;",
    REFUSAL_CASE_3: "bank card details entered incorrectly;",
    REFUSAL_CASE_4:
      "your bank card has expired. The validity period of the bank card is usually indicated on the front side of the card (the month and year until which the card is valid). The information about the validity period of the card You can find out by contacting the Bank that issued the card.",
    FOR_PAYMENT_QUESTIONS:
      "For bank card payments and other issues related to the website, You can contact the following phone number: %{phone}.",
    INFO_IS_CONFIDENTIAL:
      "The personal information provided by You (name, address, phone number, e-mail, bank card number) is confidential and not subject to disclosure. Your credit card data is transmitted only in encrypted form and is not stored on our Web server.",
    RETURN_POLICY: "Return policy",
    CASH_REFUNDS_NOT_ALLOWED:
      "Cash refunds are not allowed for card payments. The refund procedure is governed by the rules of international payment systems.",
    REFUND_REGULATED_BY:
      "The procedure for returning goods is regulated by Article 26.1 of the Federal Law “On Consumer Rights Protection”.",
    REFUND_RULE_1:
      "The consumer has the right to refuse the product at any time prior to its transfer, and after the transfer - within seven days;",
    REFUND_RULE_2:
      "The return of proper quality products is possible if its commercial appearance, consumer properties are preserved, as well as a document confirming the fact and conditions of purchase of the specified product;",
    REFUND_RULE_3:
      "The consumer does not have the right to refuse a product of proper quality that has  individually-specific properties, if the specified product can be used exclusively by the person purchasing it;",
    REFUND_RULE_4:
      "If the consumer refuses a product, the seller must return to him the sum of money paid by the consumer under the contract, with the exception of the seller’s expenses for the delivery of the returned  product from the consumer, no later than ten days from the date of the consumer's relevant request.",
    REFUND_1:
      "In order to return funds to the bank card, it is necessary to fill in the “Application for Return of Funds”, which the company sends upon request to the email address and send it along with attached a copy of Your passport to %{email}.",
    REFUND_2:
      "The refund will be made to the bank card within 21 (twenty-one) weekdays from the day of receipt of the “Application for Return of Funds” by the Company.",
    REFUND_3:
      "In order to return funds for transactions made with errors, it is necessary to submit a written application and attach a copy of the passport and the cheques/receipts confirming the erroneous write-offs. This application should be sent to %{email}.",
    REFUND_4:
      "The refund amount will be equal to the purchase amount. The period of consideration of the Application and the refund of funds shall begin from the moment the Company receives the Application and is calculated in weekdays excluding holidays/weekends.",
    CONTACT_INFO: "Contact details",
    ALLOWED_CARDS: "VISA, MasterCard are accepted for the payment.",
    COMPANY_NAME: "Company",
    INN: "Taxpayer Identification Number",
    OGRN: "Primary State Registration Number",
    KPP: "KPP",
    ADDRESS: "Address",
    PHONE: "Contact phone",
  },
  TARIFFS: {
    MAX_REQUEST_COUNT: "Max Request Count",
    MAX_USER_COUNT: "Max User Count",
    MAX_DEVICE_COUNT: "Max Device Count",
    MAX_API_REQUEST_COUNT: "Max API Request Count",
    MAX_MANAGED_TEAM_COUNT: "Max managed team count",
    COUNTRIES: "Countries",
    CREATE_NEW_TARIFF: "Create new Tariff",
    EDIT_TARIFF: "Edit Tariff",
    ALLOW_LOGO_CUSTOMIZATION: "Logo customization allowed",
    ALLOW_IP_FILTRATING: "IP restriction allowed",
    ALLOW_REQUEST_LOGS: "Access to request logs",
  },
  CURRENCIES: {
    RUBLES: "Russian roubles",
    USD: "USD",
    EURO: "Euro",
  },
  TEAMS: {
    ADD_MAIL: "Press Enter to add",
    MAILING_LIST: "Mailing List",
    SELECT_TEAM_TYPE: "-- Select team type --",
    SELECT_COUNTRY: "-- Select country --",
    TYPE_COUNTRY_NAME: "Type country name",
    OWNER: "Owner",
    TARIFF: "Tariff",
    WELCOME_TO_NATLOGGER:
      "Welcome to Natlogger SaaS platform! Please, fill information about\n" +
      "              you team below. You can create many separated teams (companies)\n" +
      "              and switch between.",
    CHANGE_TARIFF: "Change tariff",
    CREATE_TEAM_IS_MANAGED:
      "Managed team (paid from the account of the currently selected team)",
    TAX_NUMBER: "Tax ID",
    ADDRESS: "Billing address",
    PHONE: "Phone",
    EMAIL: "Email",
    WEB: "Web",
    MEMBER: "Member",
    MEMBERS: "Members",
    NOTIFICATION_RULE: "Notification rule",
    NOTIFICATION_RULES: "Notification rules",
    ORDERS: "Orders",
    COUNTRY: "Country",
    EXISTED_USER: "Existed user",
    OR_ENTER_EMAIL: "Or enter email if user not registered yet",
    PERMISSION: "Permission",
    SELECT_PERMISSION: "-- Select permission --",
    PERMISSION_NOT_SET: "Permission not set",
    USER_NOT_SET: "User not set",
    NOTIFICATION_RULE_TYPE: "Notification rule type",
    SELECT_NOTIFICATION_RULE_TYPE: "-- Select notification rule type --",
    SELECT_TARIFF: "-- Select tariff --",
    OWNERSHIP_TYPE: "Ownership type",
    TOTAL: "Total",
    STATE: "State",
    SUBSCRIPTION_CANCELLED: "Subscription cancelled",
    CREATE_NEW_TEAM: "Create new Team",
    EDIT_TEAM: "Edit Team",
    PLEASE_SET_COUNTRY: "Please set country",
    PLEASE_SELECT_TEAM_TYPE: "Please select team type",
    INVITATION_SENT_SUCCESSFULLY: "Invitation sent successfully",
    CANCEL_SUBSCRIPTION: "Cancel subscription",
    SUBSCRIPTION_CANCELLED_SUCCESSFULLY: "Subscription cancelled successfully",
    ORDER_DELETED_SUCCESSFULLY: "Order deleted successfully",
    PLEASE_SET_COUNTRY_BEFORE: "Please set country and save team before",
    TARIFF_CHANGED_PLEASE:
      "Tariff changed. Please make a payment in 'Orders' section.",
    LOGO: {
      EDIT: "Change logotype",
      UNDO: "Undo changes",
      DELETE: "Delete logotype",
    },
    DEF_EXP: "deferment until",
    ALLOWED_ADDRESSES: "Allowed IP addresses (new address - new line)",
    TYPE: "Type",
    TEAM_FIELDS_NOTE:
      "Please fill out and save these fields just once. After that, they will always be automatically pulled into invoices and acts. We need this very much, for currency and tax control of the Russian Federation.",
    COMPANY_NAME: "Company name",
    DIRECTOR_NAME: "Name and initials of the director",
    KPP: "KPP",
    OGRN: "OGRN",
    RS: "Checking account",
    BANK_NAME: "Bank",
    CORRESPONDENT_ACCOUNT: "Correspondent account",
    BIK: "Bank SWIFT code",
    PLEASE_PROVIDE_CORRECT_DATA:
      "Please fill out required fields before saving the Team",
    VIEW_ORDER_DOCS: "Generate order documents",
    UPLOAD_ORDER_DOCS: "Upload signed documents",
    PROFORMA_INVOICE: "Proforma invoice",
    ACCEPTANCE_ACT: "Acceptance act",
    INVOICE: "Invoice",
    PLEASE_SELECT_CURRENCY: "Please select currency",
    SELECT_CURRENCY: "Currency select",
    BACK_TO_DOCUMENT_SELECTION: "Back to document selection",
    ARE_YOU_SURE_YOU_WANT_TO_DELETE_ORDER:
      "Are you sure you want to delete order?",
    ARE_YOU_SURE_YOU_WANT_TO_CANCEL_SUB:
      "Are you sure you want to cancel subscription?",
    SELECT_TEAM_COUNTRY_FIRST:
      "Please select your country in the Main section first.",
  },
  USERS: {
    PATRONYMIC: "Patronymic",
    AUTHORIZATION_SCHEDULE: "Authorization schedule",
    ACTIVE_UNTIL: "Active until",
    APPROVED: "Approved",
    BLOCKED: "Blocked",
    PASSWORD: "Password",
    EMPTY_MEANS_NO_CHANGES: "Empty means no changes",
    CONFIRM_PASSWORD: "Confirm password",
    IDENTIFIERS: "Identifiers",
    CODE: "Code",
    VERIFIED: "Verified",
    CREATE_NEW_USER: "Create new User",
    EDIT_USER: "Edit User",
    PASSWORD_MISMATCH: "Password mismatch",
    USER_SUCCESSFULLY_REGISTERED: "User successfully registered",
    IDENTIFIER_VALUE_IS_NOT_SET: "Identifier value is not set",
    IDENTIFIER_TYPE_IS_NOT_SET: "Identifier type is not set",
  },
  ROLES: {
    GRANTS: "Grants",
    CREATE_NEW_ROLE: "Create new Role",
    EDIT_ROLE: "Edit Role",
  },
  TOKENS: {
    TYPE_IDENTIFIER_VALUE: "Type identifier value",
    IDENTIFIER: "Identifier",
    LAST_REQUEST: "Last request",
    CREATE_NEW_TOKEN: "Create new Token",
    EDIT_TOKEN: "Edit Token",
    VALUE: "Value",
  },
  PROPERTIES: {
    VISIBLE_FOR_ADMIN: "Visible for admin",
    CREATE_NEW_PROPERTY: "Create new Property",
    EDIT_PROPERTY: "Edit Property",
  },
  ML_MODELS: {
    CREATE_NEW_MODEL: "Create new Model",
    EDIT_MODEL: "Edit Model",
  },
  TEMPLATES: {
    SELECT_TEMPLATE_TYPE: "-- Select template type --",
    LOCALE: "Locale",
    BODY: "Body",
    CREATE_NEW_TEMPLATE: "Create new Template",
    EDIT_TEMPLATE: "Edit Template",
  },
  NOTIFICATIONS: {
    TITLE: "Title",
    SOURCE: "Source",
    DESTINATION: "Destination",
    NOTIFICATION_STATE_NAME: "Notification State Name",
    SELECT_NOTIFICATION_TYPE: "-- Select notification type --",
  },
  PASSPHRASE_TYPES: {
    VALIDATION_REGEX: "Validation Regex",
    ENCRYPTION: "Encryption",
    GENERATOR: "Generator",
    CREATE_NEW_PASSPHRASE_TYPE: "Create new Passphrase type",
    EDIT_PASSPHRASE_TYPE: "Edit Passphrase type",
  },
  TASKS: {
    SERVICE_NAME: "Service Name",
    LAST_EXECUTION_DATE: "Last Execution Date",
    EXECUTE_LOG: "Execute Log",
    CRON_EXPRESSION: "CronExpression",
    CREATE_NEW_TASK: "Create new Task",
    EDIT_TASK: "Edit Task",
    TASK: "Task",
    STATE: "State",
    METHOD: "Method",
    TASK_EXECUTION_LOG: "Task Execution Log",
  },
  FEEDBACKS: {
    QUESTION: "Question",
    TEXT: "Text",
    FEEDBACK_TYPE: "Feedback Type",
    VIEW_FEEDBACK: "View Feedback",
  },
  NEWS: {
    SORT_ORDER: "Sort Order",
    ACTIVE_FROM: "Active from",
    ACTIVE_TO: "Active to",
    NEWS: "News",
    DATE: "Date",
    BLOG: "Blog",
    CREATE_NEW_BLOG: "Create new blog element",
  },
  PERSONAL_NOTIFICATIONS: {
    IS_READ: "Is read",
  },
  REPORTS: {
    PART_TYPE: "Part type",
    SORT_ORDER: "Sort order",
    GENERATE_REPORT: "Generate Report",
    GROUP: "Group",
    VIEW: "View",
    PLEASE_SPECIFY_PARAMETERS_FOR: "Please specify parameters for {name}",
    REPORT: "Report",
    DATE_IS_NOT_SPECIFIED: "Date is not specified",
    THIS_A_GLOBAL_REPORT:
      "This is a global report - you can interact it only in read-only mode. You can create your own report with full access.",
    PARTS: "Parts",
    REPORT_PART_TYPE: "Report part type",
    ANNOTATION: "Annotation",
    SELECT_GROUP_TYPE: "-- Select group type --",
    VIEW_TYPE: "View type",
    SELECT_VIEW_TYPE: "-- Select view type --",
    CREATE_NEW_REPORT: "Create new Report",
    EDIT_REPORT: "Edit Report",
    GENERATE: "Generate",
    NEW_REPORT_PART: "New Report Part",
  },
  CHECKOUT: {
    PLEASE_ENTER_YOUR_EMAIL: "Please enter your email in Profile page",
    ORDER_HAS_INVALID_STATE: "Order has invalid state",
    CREATE_CHECKOUT: "Checkout",
    DONE: "Done",
    INFO: "Info",
    DISCOUNT: "Discount",
    SUMMARY: "Summary",
    SUCCESSFULLY_PURCHASED: "Order successfully purchased, please wait...",
    CREATION_DATE: "Creation date",
    OWNERSHIP_TYPE: "Ownership type",
    PRODUCTS: "Products",
    PRICE: "Price",
    CURRENCY: "Currency",
    PROMO_DESCRIPTION:
      "If you have a promo code, you can apply it by entering it in the field below. Otherwise, leave the field blank",
    PROMOCODE: "Promocode",
    TOTAL_AMOUNT: "Total amount",
    ALERT:
      "If you want to use the trial period, postpone the payment until its end.",
  },
  QUICK_USER: {
    HI: "Hi",
    USER_PROFILE: "User profile",
    SIGN_OUT: "Sign out",
    CURRENT_TEAM: "Current team",
    CLICK_TO_SWITCH: "Click to switch",
    ADD_TEAM: "Add team",
  },
  FOOTER: {
    FEEDBACK: "Feedback",
    PRICING: "Pricing",
    BLOG: "Blog",
    CONTACT: "Contact",
  },

  MENU: {
    ML_MODELS: "ML models",
    DASHBOARD: "Dashboard",
    MONITORING: "Monitoring",
    // DATA_SHOP: "Shop",
    DEVICES: "Devices",
    CATALOGUES: "Groups of devices",
    MESSAGES: "Messages",
    PARAMETERS: "Parameters",
    REPORTS: "Reports",
    MANAGEMENT: "Management",
    TEAMS: "Teams",
    DEVICE_TYPES: "Device types",
    DATA_PACKAGES: "Data packages",
    CONTENT: "Content",
    BLOGRU: "Blog | Russian",
    BLOGEN: "Blog | English",
    BLOGES: "Blog | Spanish",
    FINANCE: "Finance",
    ACCOUNTS: "Accounts",
    TRANSACTIONS: "Transactions",
    PAYMENTS: "Payments",
    PRODUCTS: "Products",
    DISCOUNTS: "Discounts",
    ORDERS: "Orders",
    TARIFFS: "Tariffs",
    ADMINISTRATION: "Administration",
    USERS: "Users",
    ROLES: "Roles",
    TOKENS: "Tokens",
    PROPERTIES: "Properties",
    TEMPLATES: "Templates",
    NOTIFICATIONS: "Notifications",
    PASSPHRASE_TYPES: "Passphrase types",
    TASKS: "Tasks",
    FEEDBACKS: "Feedbacks",
    DOCUMENTATION: "Documentation",
    API: "API",
    DOCUMENTATION_IMPORT: "Working with data",
    LOGS: "Logs",
    PROFILE: "Profile",
    PERSONAL_NOTIFICATIONS: "Personal notifications",
    UNITS: "Units",
  },
  DASHBOARD: {
    NONE_GROUP_TYPE_WARNING:
      "If you select a grouping type NONE or SECOND, there may be too much data, which can lead to a long load or crash of the browser. Please make sure you use a short amount of time (a day or a few hours).",
    ADD_NEW_WIDGET: "Add new widget",
    EDIT_WIDGET: "Edit widget",
    WIDGET_OPTIONS: "Widget",
    SELECT_WIDGETS: "Select widgets",
    ADD_NEW_DEVICE: "Add new device",
    CANCEL_SELECTION: "Cancel selection",
    DELETE_SELECTED: "Delete selected widgets",
    CLEAR: "Clear dashboard",
    CHOOSE_DATA_DEVICE: "Choose Data Device",
    SELECT_TYPE: "Select type",
    SELECT_ON_MAP: "Select on map",
    DATA_PACKAGE: "Select data package",
    REALTIME: "Realtime",
    OR: "or",
    DATE_RANGE: "Date range",
    WIDGET_NAME: "Widget name (optional)",
    CHART_TYPES: {
      LINE: "Line",
      SCATTER: "Scatter",
      BUBBLE: "Bubble",
      TABLE: "Table",
      PIE: "Pie",
      RADAR: "Radar",
      MAP: "Map",
      GRID: "Net",
    },
    CREATE_WIDGET: "Create widget",
    SAVE_WIDGET: "Save changes",
    WIDGET: {
      LINK_COPIED_TO_CLIPBOARD: "Link copied to clipboard",
      LEAVE_FULLSCREEN: "Leave fullscreen",
      FULLSCREEN: "Fullscreen",
      REMOVE_WIDGET: "Remove widget",
      EXPORT_CSV: "Export CSV",
      EXPORT_PNG: "Export PNG",
    },
    WIDGETS: {
      LINK_COPIED_TO_CLIPBOARD: "Link copied to clipboard",
      LEAVE_FULLSCREEN: "Leave fullscreen",
      FULLSCREEN: "Fullscreen",
      REMOVE_WIDGET: "Remove widget",
      EXPORT_CSV: "Export CSV",
      EXPORT_PNG: "Export PNG",
    },
  },

  AUTH: {
    FACE: {
      WELCOME_TO_NATLOGGER: "Welcome to Natlogger!",
      THE_ULTIMATE_SAAS: "The ultimate SaaS platform for nature research.",
      TWENTY_TWENTY: "© 2024 Natlogger",
      PRICING: "Pricing",
      BLOG: "Blog",
      CONTACTS: "Contacts",
    },
    GENERAL: {
      OR: "Or",
      SUBMIT_BUTTON: "Submit",
      NO_ACCOUNT: "Don't have an account?",
      SIGNUP_BUTTON: "Sign Up",
      FORGOT_BUTTON: "Forgot Password",
      BACK_BUTTON: "Back",
      PRIVACY: "Privacy",
      LEGAL: "Legal",
      CONTACT: "Contact",
    },
    LOGIN: {
      TITLE: "Log in Account",
      BUTTON: "Sign In",
      OR_SIGN_IN_WITH: "Or sign in with:",
      REPAIR_PASSWORD: "Repair password",
      PASSWORD_IS_REQUIRED: "Password is required.",
      PASSWORD: "Password",
      EMAIL_IS_REQUIRED: "Enter a valid email address.",
      EMAIL_ADDRESS: "Email address",
      ENTER_YOUR_USERNAME_AND_PASSWORD: "Enter your email and password",
      SIGN_IN: "Sign In",
      DONT_HAVE_AN_ACCOUNT_YET: "New here?",
      SIGN_UP: "Sign Up!",
    },
    FORGOT: {
      TITLE: "Forgot Password?",
      DESC: "Enter your email to reset your password",
      SUCCESS: "Your account has been successfully reset.",
    },
    REGISTER: {
      TITLE: "Sign Up",
      CODE_IS_REQUIRED: "Code is required.",
      DESC: "Enter your details to create your account",
      SUCCESS: "Your account has been successfully registered.",
      ALREADY_HAVE_AN_ACCOUNT: "Already have an account?",
      VERIFICATION_CODE:
        "Verification code has been sent to the specified Email",
      VERIFICATION_CODE_MUST_HAVE: "Verification code must have 6 letters.",
      NAME_IS_REQUIRED: "Name is required.",
      SURNAME_IS_REQUIRED: "Surname is required.",
      PASSWORD_IS_REQUIRED: "Password is required.",
      NAME_MUST_HAVE: "Name must have at most 35 letters.",
      SURNAME_MUST_HAVE: "Surname must have at most 35 letters.",
      EMAIL_IS_REQUIRED: "Email is required",
      EMAIL_MUST_HAVE: "Email must have at most 254 letters.",
      PASSWORD_MUST_HAVE_LEAST: "Password must have at least 3 letters.",
      PASSWORD_MUST_HAVE_MOST: "Password must have at most 50 letters.",
      PASSWORD: "Password",
      PASSWORD_CONFIRMATION_IS_REQUIRED: "Password confirmation is required.",
      PASSWORD_MUST_BE_IDENTICAL: "Passwords must be identical.",
      VALIDATION_SUCCESSFULLY:
        "Validation successfully completed, please wait...",
      CODE: "Code",
      VERIFICATION_CODE_PLACEHOLDER: "Verification code",
    },
    REPAIR: {
      ALREADY_REMEMBER_YOUR_ACCOUNT: "Already remember your account?",
      SIGN_IN: "Sign In!",
      PASSWORD_REPAIR: "Password repair",
      NEXT: "Next",
      ENTER_YOUR_KNOWN_DETAILS:
        "Enter the required information to restore access to your account",
      CODE_MUST_HAVE: "Code must have 6 letters.",
      CHANGE_PASSWORD: "Change password",
      PASSWORD_SUCCESSFULLY_CHANGED:
        "Password successfully changed, please sign in",
    },
    INPUT: {
      EMAIL: "Email",
      FULLNAME: "Fullname",
      PASSWORD: "Password",
      CONFIRM_PASSWORD: "Confirm Password",
      USERNAME: "Username",
    },
    VALIDATION: {
      NAME_REQUIRED: "Name is required.",
      NAME_MAX_LENGTH: "Name must have at most {length} letters.",
      SURNAME_REQUIRED: "Surname is required.",
      SURNAME_MAX_LENGTH: "Surname must have at most {length} letters.",
      EMAIL_REQUIRED: "Email is required.",
      EMAIL_FORMAT: "Correct format is user@example.com",
      EMAIL_MAX_LENGTH: "Email must have at most {length} letters.",
      PASSWORD_MIN_LENGTH: "Password must be at least {length} letters.",
      PASSWORD_MAX_LENGTH: "Password must have at most {length} letters.",
      PASSWORD_IDENTICAL: "Passwords must be identical.",
      INVALID: "{{name}} is not valid",
      REQUIRED: "{{name}} is required",
      MIN_LENGTH: "{{name}} minimum length is {{min}}",
      AGREEMENT_REQUIRED: "Accepting terms & conditions are required",
      NOT_FOUND: "The requested {{name}} is not found",
      INVALID_LOGIN: "The login detail is incorrect",
      REQUIRED_FIELD: "Required field",
      MIN_LENGTH_FIELD: "Minimum field length:",
      MAX_LENGTH_FIELD: "Maximum field length:",
      INVALID_FIELD: "Field is not valid",
      PLEASE_PROVIDE_CORRECT_DATA: "Please, provide correct data!",
    },
  },
  BLOG: {
    TITLE: "Title",
    TEXT: "Text",
    IMAGE_URL: "Image URL",
    WASTE: "Minutes to read",
  },
  API: {
    H1: "Natlogger API",
    H2: "General description",
    ABOUT:
      "The REST approach is used as an architectural style for interacting with the Natlogger API, it will allow you to conveniently operate with data.",
    H3: "What is REST?",
    REST:
      "REST (restful) is the general principles of organizing the interaction of an application/site with a server using the HTTP protocol. " +
      "The peculiarity of REST is that the server does not remember the state of the user between requests - each request contains information that identifies the user (for example, a token) and all parameters necessary to perform the operation.",
    READ_MORE: "You can read more about REST architecture",
    HERE: "here",
    DATA: "Data for interacting with the Natlogger API",
    HOST: "Host for API access",
    TOKEN: "Token",
    TOKEN_NOT_AVAILABLE: "Not available for your tariff",
    TOKEN_DES: "put the Token in the Request Header.",
    TEAM: "Your team ID",
    DEFINES: "Additional data for queries",
    GROUP_TYPE: "Data grouping",
    REAL_TIME_RANGE: "Time range (in hours)",

    SHOW: "Show",
    GROUP: {
      NONE: "No grouping",
      AUTO: "Auto-fit depending on the time period",
      SECOND: "Second",
      MINUTE: "Minute",
      HOUR: "Hour",
      DAY: "Day",
      WEEK: "Week",
      MONTH: "Month",
      YEAR: "Year",
    },
    METHODS: "Methods for interacting with the Natlogger API",
    PARAMETERS: "Receiving a list of parameters",
    DEVICES: "Receiving a list of devices",
    MEASURE: "Receiving data",
    CIRCLE: "Receiving data grouped by value",
    CSV: "Receiving raw data as CSV",
    MAIN: "Main",
    HTTP: "HTTP method:",
    JSON: "The request returns a response in json format",
    CSV_FILE: "The request returns a response as a csv file",
    REQUEST_PARAMS: "Request parameters",
    REQUEST: "Request",
    RESPONSE: "Response",
    EXAMPLE: "Usage example",
    REQUIRED: "Required",
    OPTIONAL: "Optional",
    UNIT_ID: "Units",
    CALCULATE: "Calculate the total number of records in the database",
    ENRICH: "Enrichment with reference data",
    COUNT: "Number of selection items",
    START: "Selection starting element",
    SORT_FIELD: "Sorting field",
    SORT_DIR: "Sorting direction",
    NAME: "Parameter name",
    DEVICE_TYPE_IDS: "Devices to be sampled as a set of their IDs",
    PARAM_IDS: "Parameters for sampling as a set of their IDs",
    DEVICE_IDS: "Devices for sampling as a set of their IDs",
    DATA_PACKAGE_ID: "Data package ID for sampling",
    PUBLIC: "Public",
    LATITUDE: "Point latitude",
    LONGITUDE: "Point longitude",
    RADIUS: "Search radius",
    START_DATE: "Selection start date",
    END_DATE: "Selection end date",
    GROUP_TYPES: "Data grouping",
    ROUND: "Precision",
  },
  DOCUMENTATION_IMPORT: {
    WORK: "Working with data",
    IMPORT: "Data import",
    IMPORT_LI1: "Prepare a file",
    IMPORT_EXAMPLE1: "Sample file: ",
    IMPORT_EXAMPLE2: "Protocol documentation: ",
    IMPORT_LI2: "Create a new virtual device",
    IMPORT_LI3: "Load prepared file via ",
    SOKOL: "Loading data from SokolMeteo",
    SOKOL_LI1:
      "Download data from SokolMeteo for the weather station you are interested in",
    SOKOL_LI2: "Create a new weather station (virtual device)",
    SOKOL_LI3: "Load prepared file via data import",
  },
  PROFILE: {
    ACCOUNT: "My Account",
    CHANGE: "Change photo",
    UNDO: "Undo changes",
    DELETE: "Delete photo",
    ABOUT: "About",
    NAME: "Name",
    NAME_ENTER: "Enter your name",
    NAME_REQUIRED: "Name is required.",
    NAME_MAX_LENGTH: "Name must have at most 35 letters.",
    SURNAME: "Surname",
    SURNAME_ENTER: "Enter your surname",
    SURNAME_REQUIRED: "Surname is required.",
    SURNAME_MAX_LENGTH: "Surname must have at most 35 letters.",
    DEFAULT_CHANEL: "Set as default notification channel",
    EMAIL: "Email",
    EMAIL_ENTER: "Enter your email",
    EMAIL_REQUIRED: "Email is required.",
    EMAIL_FORMAT: "Correct format is user@example.com",
    EMAIL_MAX_LENGTH: "Email must have at most 254 letters.",
    PHONE: "Phone",
    PHONE_ENTER: "Enter your phone",
    TELEGRAM: "Telegram ID",
    TELEGRAM_ENTER: "Enter your telegram ID",
    SECURITY: "Security",
    PASSWORD: "Password",
    PASSWORD_ENTER: "Enter your password",
    PASSWORD_CONFIRMATION: "Password Confirmation",
    PASSWORD_CONFIRMATION_ENTER: "Confirm your password",
    PASSWORD_IDENTICAL: "Passwords must be identical.",
    SAVE: "Save changes",
  },
  LOGS: {
    CREATED: "Request date",
    USER_ID: "User ID",
    METHOD: "Method",
  },
  MULTI_SG: {
    CHOOSE: "Choose",
    SELECTED: "Selected",
  },
  WIDGET: {
    SELECT_ALL_PARAMETERS: "Select all parameters",
    PARAMETERS: "Parameters",
  },
  UNIT: {
    NAME: "Name",
    NAME_EN: "Name (English)",
    NAME_RU: "Name (Russian)",
    NAME_ES: "Name (Spanish)",
    CODE: "Designation",
    CODE_NOT_SET: "Designation not set",
  },
  DATA_SHOP: {
    VOTES: "{votes} votes",
    NO_VOTES: "No votes",
    RATING: "Rating: {rating}/{votes}",
    DATA_COLLECTION_POINTS_COUNT: "Data collection points count",
    DATA_COLLECTION_PERIOD: "Data collection period",
    OTHER_DATA_PACKAGES: "Other data packages",
    DATA_COLLECTION_POINTS_ON_MAP: "Data collection points on the map",
    SHOW_ON_MAP: "Show on map",
    DATA_PACKAGES: "Data Packages",
    SHOW: "Show",
    VOTE: "Review",
    VOTE_MODAL_HEADER: "Review",
    VOTING_SUCCESS: "Thank you for your feedback",
    VOTING_THANK_YOU: "Success",
    ML_MODEL_ALLOWED_PARAMETERS: "Compatible with parameters",
    ML_MODEL_TYPE: "Model type",
    MODELS: "Models",
    OTHER_MODELS: "Other models",
  },
  pricing: {
    buy: "Buy",
    unlimited: "Unlimited",
    none: "Not",
    yes: "Yes",
    up: "Up to",
    individual: "Individually",
    days: "days",
    month: "/month",
    year: "/year",
    reg: "Register now",
    call: "Contact us",
    desc: "In the table, you can see the capabilities, feature sets and platform prices for different types of users",
    choose: "Choose a tariff",
    head: {
      leader: "DECISION MAKER",
      analyst: "ANALYST TECH",
      consultant: "CONSULTANT PRO",
      special: "ENTERPRISE",
    },
    row1: {
      head: "Number of team members",
      analyst: {
        desc: "Additional user $12",
      },
      consultant: {
        desc: "Additional user $12",
      },
    },
    row2: {
      head: "Number of devices",
      analyst: {
        desc: "Additional device $12",
      },
      consultant: {
        desc: "Additional device $12",
      },
    },
    row3: {
      head: "Free trial period",
    },
    row4: {
      head: "Technical support",
      analyst: {
        text: "24/7",
        desc: "E-mail, Skype",
      },
      consultant: {
        text: "24/7",
        desc: "E-mail, Skype, WhatsApp, Telegram, TeamViewer",
      },
      special: "Personal 24/7",
    },
    row5: {
      head: "Service customization using the user's logo",
    },
    row6: {
      head: "Persistent data sources",
      leader: "Only your devices",
      analyst: "Only your devices",
      consultant: "Your end customer devices + API",
      special: "Individually + API + AI",
    },
    row7: {
      head: "Report generation",
      leader: "Only reading",
    },
    row8: {
      head: "Logging user visits",
    },
    row9: {
      head: "Limiting visits by IP",
    },
    row10: {
      head: "Service Level Agreement",
    },
    row11: {
      head: "The main features",
      el1: "Working with data ready for evaluation on dashboards, graphs and reports",
      el2: "Observation and decision making",
      el3: "Passing datasets by reference",
      el4: "Assignments to ANALYST TECH and CONSULTANT PRO",
      el5: "Connecting devices to the platform",
      el6: "Formation of a team of ANALYSTS TECH and DECISION MAKER",
      el7: "Uploading and processing your own datasets to the platform",
      el8: "Working with your own raw, clean, gap-free data (small data)",
      el9: "Setting your own limit parameters",
      el10: "Setting your own alerts",
      el11: "Preparing and sending reports",
      el12: "Preparing data for DECISION MAKER",
      el13: "Platform customization using your logo",
      el14: "Obtaining available datasets via API",
      el15: "Almost everything you need for doing business, research or teaching",

      special: {
        el1: "Integration with CRM",
        el2: "Data sets divided by: country, current, forecast, historical, historical forecast",
        el3: "Satellite images",
        el4: "Maps",
        el5: "Models",
        el6: "AI",
      },
    },
    row12: {
      head: "API access",
      consultant: {
        el1: "Data arrays",
      },
      special: {
        el1: "Users with special requirements",
        el2: "Organizations and institutions that require local or closed systems and operations in large command centers",
        el3: "Government agencies",
        el4: "Large industrial enterprises",
        el5: "Consulting corporations",
      },
    },
    row13: {
      head: "For whom",
      leader: {
        el1: "Decision makers",
        el2: "Leaders",
        el3: "Managers",
        el4: "Inspectors",
        el5: "Supervisors",
      },
      analyst: {
        el1: "Independent companies using the platform only for their own needs",
        el2: "Small and medium-sized industrial enterprises",
        el3: "Housing and communal services, management companies and homeowners associations",
        el4: "Architects",
        el5: "Builders",
        el6: "Agronomists",
        el7: "Ecologists",
        el8: "Safety, health and environmental engineers",
        el9: "Staff analysts and statisticians",
        el10: "Remote sensing and sensing specialists",
        el11: "Dedicated operational specialists from the industry",
        el12: "Mining enterprises",
        el13: "Road sector enterprises",
        el14: "School circles on ecology, environmental protection, climate change, sustainable development and the Internet of things",
      },
      consultant: {
        el1: "Consultants and consulting companies in the field of ecology, environmental protection and risk management, as well as laboratories that have their own end customers under the plan DECISION MAKER",
        el2: "Companies providing environmental telematics / environmental telematics services",
        el3: "Companies specializing in monitoring the environment (including air, water, soil, vegetation, climate and weather among others) using satellite imagery to refine their data using a network of ground sensors",
        el4: "Scientists, teachers and teachers who work with a large number of students performing practice / laboratory work according to the ANALYST TECH plan",
        el5: "Data Scientists and Big Data Professionals",
      },
      special: {
        el1: "Users with special requirements",
        el2: "Organizations and institutions that need local or closed systems and operation in large dispatch centers",
        el3: "State structures",
        el4: "Large industrial enterprises",
        el5: "Consulting corporations",
      },
    },
  },
};
